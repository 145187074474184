import { firestore, storage } from "@/config/firebase";
import Activity from "@/models/activity.model";
import RecordInterface from "@/models/interfaces/record.interface";

const activityCollectionRef = firestore.collection("activities");

export function getActivity(id: string) {
  return activityCollectionRef
    .doc(id)
    .withConverter(Activity.converter)
    .get()
    .then(snapshot => {
      return snapshot.data();
    });
}

export function getActivities(courseId: string) {
  return activityCollectionRef
    .where("courseId", "==", courseId)
    .withConverter(Activity.converter)
    .get()
    .then(querySnapshot => {
      const activities: Activity[] = [];
      querySnapshot.forEach(snapshot => {
        activities.push(snapshot.data());
      });
      return activities;
    });
}

export async function getStudentStatusByClassroom(classroomId: string) {
  return firestore
    .collection("classroomRecords")
    .doc(classroomId)
    .collection("studentRecords")
    .get()
    .then(querySnapshot => {
      const records: Record<string, unknown> = {};
      querySnapshot.forEach(snapshot => {
        const record = snapshot.data();
        records[snapshot.id] = record;
      });
      return records;
    });
}

export async function getStudentStatus(classroomId: string, studentId: string) {
  return firestore
    .collection("classroomRecords")
    .doc(classroomId)
    .collection("studentRecords")
    .doc(studentId)
    .get()
    .then(snapshot => {
      return snapshot.data();
    });
}

export function getActivityRecord(
  studentId: string,
  classroomId: string,
  activityId: string
) {
  return firestore
    .collection("classroomRecords")
    .doc(classroomId)
    .collection("studentRecords")
    .doc(studentId)
    .collection("activityRecords")
    .doc(activityId)
    .get()
    .then(snapshot => {
      return snapshot.data();
    });
}

export async function mergeActivityRecord(
  activityId: string,
  record: RecordInterface
) {
  return firestore
    .collection("classroomRecords")
    .doc(record.classroomId)
    .collection("studentRecords")
    .doc(record.studentId)
    .collection("activityRecords")
    .doc(activityId)
    .set(record, { merge: true });
}

export function addActivity(activity: object) {
  return firestore.collection("activities").add(activity);
}

export function deleteActivity(activityId: string) {
  return firestore
    .collection("activities")
    .doc(activityId)
    .delete();
}

export function updateActivity(activityId: string, update: object) {
  return firestore
    .collection("activities")
    .doc(activityId)
    .update(update);
}

export async function updateFile(activity: Activity, file: File) {
  const uploadRef = storage.child(
    `activities/${activity.id}/content/${file.name}`
  );

  try {
    await uploadRef.put(file);
  } catch (error) {
    alert(error.message);
  }

  return uploadRef.getDownloadURL();
}
