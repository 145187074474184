<template>
  <div>
    <h2>Exercise 6</h2>
    <p>
      The arrangement of your code determines the sequence where your code will
      be run.
    </p>
    <img src="@/assets/foundation/variable_sequence.png" />
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "orange"
          <br />
          >> "pear"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Add print
            <span class="block block-variable">number</span> statements to the
            code in workspace so that it will produce the expected result below.
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 3
          <br />
          >> 6
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
