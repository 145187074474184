/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let a = 5;
let b = 7;
let c = 12;

// Reassign variable a, b and c below this line
`;

const seedTest = `
  chaiAssert(a === 5, "a should have a value of 5 when defined");
  chaiAssert(b === 7, "b should have a value of 7 when defined");
  chaiAssert(c === 12, "c should have a value of 12 when defined");
`;

export const testCases = `
  chaiAssert.strictEqual(a, 2, "a");
  chaiAssert.strictEqual(b, 441, "b");
  chaiAssert.strictEqual(c, 7, "c");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 4; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);
  assert(
    /a\s*=\s*a\s*-\s*3\s*;|a\s*-=\s*3;/.test(code),
    "Reassign a with operator on its existing value"
  );

  assert(
    /b\s*=\s*b\s*\*\s*63\s*;|b\s*=\s*63\s*\*\s*b\s*;|b\s*\*=\s*63\s*;/.test(
      code
    ),
    "Reassign b with operator on its existing value"
  );

  assert(
    /c\s*=\s*84\s*\/\s*c\s*;/.test(code),
    "Reassign c with operator on its existing value"
  );

  window.console = oldConsole;
  return true;
}
