<template>
  <div>
    <h2>Exercise 3</h2>
    <p>
      Similarly, we can distinguish a String and a Boolean as String is enclosed
      with a pair of quotes. Notice in the second print statement the there does
      not quote around the word "true".
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">myBooleanString</span> and assign
            it with a string of <span class="block block-string">true</span>.
          </li>
          <li>
            Create a variable named
            <span class="block block-variable">myBoolean</span> and assign it
            with a logic of <span class="block block-logic">true</span>.
          </li>
          <li>
            Print <span class="block block-variable">myBooleanString</span> and
            <span class="block block-variable">myBoolean</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "true"
          <br />
          >> true
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
