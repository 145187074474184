<template>
  <div class="home">
    <b-container class="my-5">
      <b-row>
        <b-col md="12">
          <div class="d-flex justify-content-between align-items-center">
            <h2>🏫 Your Classrooms</h2>
            <div>
              <b-button variant="dark" size="sm" v-b-modal.modal-create-class>
                Create classroom
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="classrooms.length == 0 && !isLoading">
        <b-col class="mt-3" md="12">
          <p>
            👀 Looks like you do not have any class.
            <br />
            Create a class and start assign courses to students 👩‍🎓👨‍🎓
          </p>
        </b-col>
      </b-row>

      <b-row class="mt-3" v-else>
        <b-col
          md="4"
          v-for="classroom in classrooms"
          :key="classroom.id"
          class="mb-3"
        >
          <b-card
            no-body
            class="overflow-hidden card-hover h-100"
            @click="onClickClassroom(classroom)"
          >
            <b-card-body :title="classroom.name">
              <b-card-text>
                <b-row>
                  <b-col sm="12" class="mb-1">
                    👩‍💻 {{ classroom.studentIds.length }} students
                  </b-col>
                  <b-col sm="12">
                    🖥️ {{ classroom.courseCount }} courses
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="isLoading">
        <b-col class="mb-3" md="4" v-for="i in 6" :key="i">
          <b-card no-body class="overflow-hidden card-hover mt-3 h-100">
            <b-card-body>
              <b-card-text>
                <b-skeleton
                  height="1.5em"
                  class="mb-3"
                  width="70%"
                ></b-skeleton>
                <b-row>
                  <b-col lg="12"><b-skeleton width="40%"></b-skeleton></b-col>
                  <b-col lg="12"><b-skeleton width="40%"></b-skeleton></b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-create-class"
      ref="modal"
      title="Create Class"
      ok-variant="dark"
      @show="resetCreateClassModal"
      @hidden="resetCreateClassModal"
      @ok="handleOkCreateClass"
      :ok-disabled="isCreatingClass"
      :no-close-on-backdrop="isCreatingClass"
      :hide-header-close="isCreatingClass"
      :no-close-on-esc="isCreatingClass"
      :cancel-disabled="isCreatingClass"
    >
      <form ref="addClassForm" @submit.stop.prevent="handleSubmitCreateClass">
        <b-form-group
          label="Name"
          invalid-feedback="Name is required"
          :state="newClassNameState"
        >
          <b-form-input
            type="text"
            v-model="newClassName"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-ok>
        <b-spinner small v-if="isCreatingClass"></b-spinner>
        {{ isCreatingClass ? "Loading..." : "Create Class" }}
      </template>
    </b-modal>
  </div>
</template>

<script>
import { classroomServices } from "@/services";
import { ClassroomBuilder } from "@/models/builders/classroom.builder";
import { mapGetters } from "vuex";

export default {
  name: "TeacherDashboard",

  data() {
    return {
      classrooms: [],
      newClassName: null,
      newClassNameState: null,
      isCreatingClass: false,
      isLoading: true
    };
  },

  async created() {
    await this.getClassrooms();
    this.isLoading = false;
  },

  computed: {
    ...mapGetters({
      isLicensed: "organisation/isLicensed"
    })
  },

  methods: {
    getClassrooms() {
      return classroomServices
        .getTeacherClassrooms(this.$store.state.user.id)
        .then(classrooms => {
          this.classrooms = classrooms;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    onClickClassroom(classroom) {
      this.$router.push(`classroom/${classroom.id}/edit`);
    },

    async handleSubmitCreateClass() {
      const valid = this.$refs.addClassForm.checkValidity();
      this.newClassNameState = valid;

      if (!valid) {
        return;
      }

      this.isCreatingClass = true;

      const classroom = new ClassroomBuilder()
        .setName(this.newClassName)
        .setOrganisationId(this.$store.state.organisation.id)
        .setCreatedBy(this.$store.state.user.id)
        .setCreatedAtAsCurrent()
        .build();

      console.log(classroom);

      const newClassroomRef = await classroomServices
        .addClassroom(classroom)
        .catch(error => {
          alert(error.message);
        });

      await classroomServices
        .updateClassroom(newClassroomRef.id, {
          classCode: newClassroomRef.id.substring(0, 6)
        })
        .catch(error => {
          alert(error.message);
        });

      this.$router.push(`/classroom/${newClassroomRef.id}/edit`);
      return;
    },

    handleOkCreateClass(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmitCreateClass();
    },

    resetCreateClassModal() {
      this.newClassName = "";
      this.newClassNameState = null;
    }
  }
};
</script>
