/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let car = {
  colour: "red",
  wheels: 4,
  type: "suv",
  maxSpeed: 300
};

// Only write code below this line
`;

const seedTest = `
  let answer = {
    colour: "red",
    wheels: 4,
    type: "suv",
    maxSpeed: 300
  };
  chaiAssert.deepEqual(car, answer, "Do not change default code");
`;

export const testCases = `
  chaiAssert.deepEqual(values, ["red", 4, "suv", 300], 'values should be equal to ["red", 4, "suv", 300]');
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 6; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /for\s*\([\s\S]+in[\s\S]+\)\s*{[\s\S]+}/.test(code),
    "use for in loops"
  );
  window.console = oldConsole;
  return true;
}
