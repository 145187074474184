<template>
  <div>
    <h2>Exercise 7</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">meter</span> and assign it with a
            value of <span class="block block-math">3.5</span>
          </li>
          <li>
            Create a variable named
            <span class="block block-variable">centimeter</span> and assign it
            as a value of
            <span class="block block-variable">meter</span> multiply
            <span class="block block-math">100</span>
          </li>
          <li>Print <span class="block block-variable">centimeter</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 350
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
