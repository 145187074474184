/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let animal = "cat";
let fruit = "apple";
let result;

function checkResult(){
// Only write code below this line





// Only write code above this line
  return
}
`;

const seedTest = `
  chaiAssert(animal === "cat", "Do not change the default code");
  chaiAssert(fruit === "apple", "Do not change the default code");
  chaiAssert(result === undefined, "Do not change the default code");
`;

export const testCases = `
  animal = "dog";
  fruit = "apple";
  checkResult();
  chaiAssert(result === "Yes", "Wrong result value when both conditions are true");

  animal = "cat";
  fruit = "apple";
  checkResult();
  chaiAssert(result === "Yes", "Wrong result value when condition for animal is false while fruit is true");

  animal = "dog";
  fruit = "orange";
  checkResult();
  chaiAssert(result === "Yes", "Wrong result value when condition for animal is true while fruit is false");

  animal = "cat";
  fruit = "orange";
  checkResult();
  chaiAssert(result === "No", "Wrong result value when both conditions are false");

`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 3; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /if\s*\([\s\S]+\|\|[\s\S]+\)\s*{[\s\S]+}\s*else\s*{[\s\S]+}/.test(code),
    "use if...else statement with AND operator"
  );

  window.console = oldConsole;
  return true;
}
