<template>
  <div>
    <h2>Data Types</h2>
    <p>
      In many programming languages, a variable has a
      <strong>data type</strong> and we can only put in the information of the
      same data type to the variable! Some common data types including:
    </p>
    <ul>
      <li>Number (1, 180, 32.22, etc…)</li>
      <li>String (“Hello”, “Nice to meet you!”, etc..)</li>
      <li>Boolean (true or false)</li>
    </ul>

    <p>
      Blockly is a forgiving language. It allows us to assign a value of any
      data type to a variable. Programming languages such as Python and
      JavaScript share such behavior.
    </p>
    <p>
      In other programming languages such as Java and C++, you will need to
      specify exactly what type of data will be assigned to that variable. If a
      value of a different type is assigned to the variable, the program will
      run into an error.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">favouriteAnimal</span> and assign
            it to a string <span class="block block-string">cat</span>
          </li>
          <li>
            Create a variable
            <span class="block block-variable">isCodingFun</span> and assign it
            to a boolean
            <span class="block block-logic">true</span>
          </li>
          <li>
            Create a variable <span class="block block-variable">year</span> and
            assign it to the number <span class="block block-math">2020</span>
          </li>
          <li>
            Print variables
            <span class="block block-variable">favouriteAnimal</span>,
            <span class="block block-variable">isCodingFun</span>, and
            <span class="block block-variable">year</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "cat"
          <br />
          >> true
          <br />
          >> 2020
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
