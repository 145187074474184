/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;
export const testCases = `
  chaiAssert.isDefined(luckyNumber);
  chaiAssert.isNumber(luckyNumber, "luckyNumber is expected to be a number");
  chaiAssert(luckyNumber === 7, "luckyNumber should be assigned value of 7");
  chaiAssert.isDefined(fruit);
  chaiAssert.isString(fruit, "fruit is expected to be a number");
  chaiAssert(fruit === "apple", "fruit should be assigned value of apple");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;
  window.console = customConsole(self);
  eval(code + testCases);
  assert(
    /let\s+luckyNumber/.test(code),
    "declare luckyNumber using the let keyword"
  );
  assert(/const\s+fruit/.test(code), "declare fruit using the const keyword");
  window.console = oldConsole;
  return true;
}
