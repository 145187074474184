/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let message = "";

// Only write code below this line`;

const seedTest = `
  chaiAssert(message === "", "Do not change default code");
`;

export const testCases = `
  multiplyTen(2);
  chaiAssert.deepEqual(message, "2 multiple by 10 is 20", "Wrong message when argument is 2");

  multiplyTen(0);
  chaiAssert.deepEqual(message, "0 multiple by 10 is 0", "Wrong message when argument is 0");

  multiplyTen(-5);
  chaiAssert.deepEqual(message, "-5 multiple by 10 is -50", "Wrong message when argument is -5");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /function multiplyTen\([\s\S]+\)\s*{[\s\S]+}/.test(code),
    "multiplyTen function not declared"
  );

  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
