/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let a = 2;
let day;

function checkResult() {
// Only rewrite code below this line

  if (a == 1) {
    day = "Monday";
  } else if (a == 2) {
    day = "Tuesday";
  } else if (a == 3) {
    day = "Wednesday";
  } else if (a == 4) {
    day = "Thursday";
  } else if (a == 5) {
    day = "Friday";
  } else {
    day = "None";
  }

// Only rewrite code above this line
}`;

const seedTest = `
  chaiAssert(a === 2, "Do not change the default code");
  chaiAssert(day === undefined, "Do not change the default code");
`;

export const testCases = `
  a = 1;
  checkResult();
  chaiAssert(day === "Monday", "Wrong result when a is 1");

  a = 2;
  checkResult();
  chaiAssert(day === "Tuesday", "Wrong result when a is 2");

  a = 3;
  checkResult();
  chaiAssert(day === "Wednesday", "Wrong result when a is 3");

  a = 4;
  checkResult();
  chaiAssert(day === "Thursday", "Wrong result when a is 4");

  a = 5;
  checkResult();
  chaiAssert(day === "Friday", "Wrong result when a is 5");

  a = 10;
  checkResult();
  chaiAssert(day === "None", "Wrong result when a is anything else");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(/switch\s*\([\s\S]+\)\s*{[\s\S]+}/.test(code), "use switch operator");

  eval(code + testCases);

  window.console = oldConsole;
  return true;
}
