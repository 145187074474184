/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let randomNumber = 500;
let message;

function checkNumber() {
// Only write code below this line




// Only write code above this line
return message;
}`;

const seedTest = `
  chaiAssert(randomNumber === 500, "Do not change the default code");
  chaiAssert.isUndefined(message, "Do not change the default code");
`;

export const testCases = `
  randomNumber = 2000;
  checkNumber();
  chaiAssert(message === "More than or equal 1000", "Wrong message value when randomNumber is more than 1000");

  randomNumber = 1000;
  checkNumber();
  chaiAssert(message === "More than or equal 1000", "Wrong message value when randomNumber equals 1000");

  randomNumber = 500;
  checkNumber();
  chaiAssert(message === "More than or equal 100", "Wrong message value when randomNumber is more than 100");

  randomNumber = 100;
  checkNumber();
  chaiAssert(message === "More than or equal 100", "Wrong message value when randomNumber equals 100");

  randomNumber = 10;
  checkNumber();
  chaiAssert(message === "Less than 100", "Wrong message value when randomNumber more less 100");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /if\s*\([\s\S]+\)\s*{[\s\S]+}\s*else if\s*\([\s\S]+\)\s*{[\s\S]+}\s*else\s*{[\s\S]+}/.test(
      code
    ),
    "use an if..else if..else statement"
  );

  eval(code + testCases);

  window.console = oldConsole;
  return true;
}
