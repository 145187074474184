<template>
  <div>
    <h2>Exercise 14</h2>
    <p>
      In this exercise, we will create a conditional expression to check if a
      number is an even number. Else, it's an odd number.
    </p>
    <p class="mb-0">
      If a number is divisible by 2 with no remainder, then it is even.
    </p>
    <img class="img-fluid" src="@/assets/foundation/long-division.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use a if statement to check if
            <span class="block block-variable">number</span> is an even number.
            If true, print a message
            <span class="block block-string">It is an even number!</span>
          </li>
        </ol>
        <p class="mt-2 mb-0">
          * Use only equal operator to solve this activity
        </p>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
