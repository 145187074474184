/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let someArray = [7, 56, 24, 18]

// Only write code below this line
`;

const seedTest = `
  chaiAssert.deepEqual(someArray, [7, 56, 24, 18], "Do not change default code");
`;

export const testCases = `
  chaiAssert(someNumber === 7, "someNumber should be equal to 7");
  chaiAssert.deepEqual(someArray, [7, 56, 3, 18]);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);

  assert(
    /someNumber\s*=\s*someArray\[0\]\s*;/.test(code),
    "someNumber should be assigned with element from array"
  );

  assert(
    /someArray\[2\]\s*=\s*3\s*;/.test(code),
    "replace the third element of someArray"
  );

  window.console = oldConsole;
  return true;
}
