<template>
  <div>
    <h2>Updating Variables</h2>
    <p>
      Many times we will need to reassign a variable by performing some math
      operation with its original value. For example, if you have a variable to
      count the money you have in your bank account, you need to update the
      variable based on its existing value.
    </p>
    <p>
      In the example below, we reassign the value of
      <span class="block block-variable">total</span> as it's previous value
      plus 1. The final value of
      <span class="block block-variable">total</span> will be 10
    </p>
    <img class="img-fluid" src="@/assets/foundation/operator_three.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Update <span class="block block-variable">myMoney</span> variable by
            adding 20 to its existing value
          </li>
          <li>Print <span class="block block-variable">myMoney</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 170
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
