export const tools = [
  {
    name: "Text",
    description: "Visualize how binary is used to represent text",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/tools%2Fbinary-text.png?alt=media&token=37f5fb77-7811-4560-aaaf-6e4a9d610520",
    route: "/tool/binary-representation-of-text",
    isPremium: false
  },
  {
    name: "Images",
    description: "Visualize how binary is used to represent images",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/tools%2Fbin-images.png?alt=media&token=3b06cc03-0198-4fa1-857d-46acd54cbf27",
    route: "/tool/binary-representation-of-images",
    isPremium: false
  },
  {
    name: "Sound",
    description: "Visualize how binary is used to represent sound",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/tools%2Fbin-sound.png?alt=media&token=25925e3d-391d-4e30-ba69-9666071ed0aa",
    route: "/tool/binary-representation-of-sound",
    isPremium: false
  },
  {
    name: "Text Compression",
    description: "Intractive tool to understand lossless text compression",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/tools%2Ftext-compression.png?alt=media&token=6b8e1be1-48e7-406a-84cf-ce67935bebf3",
    route: "/tool/lossless-text-compression",
    isPremium: false
  },
  {
    name: "Number System",
    description: "Conversion between different number systems",
    route: "/tool/number-system",
    isPremium: false,
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/codehive-v2.appspot.com/o/tools%2Fnumber-system.png?alt=media&token=d5617a30-6d82-422e-b258-678a71f68ce2"
  }
];
