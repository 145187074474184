/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;

const seedTest = `
`;

export const testCases = `
  chaiAssert.isArray(randomArray, "randomArray");
  chaiAssert(randomArray.length == 3, 'randomArray should have 3 elements');
  chaiAssert.isNumber(randomArray[0], 'First element');
  chaiAssert.isNumber(randomArray[1], 'Second element');
  chaiAssert.isNumber(randomArray[2], 'Third element');
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
