<template>
  <div>
    <h2>Exercise 12</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <p>
          In this activity, we will create a simple algorithm to check if a
          person is old enough to drive. The legal age to drive is 18 years old.
        </p>
        <ol class="activity-list">
          <li>
            Create a variable called
            <span class="block block-variable">myAge</span> and set it to any
            number equal or greater than 18
          </li>
          <li>
            Use a if statement to check whether
            <span class="block block-variable">myAge</span> is greater or equal
            to 18, if so, print a message
            <span class="block block-string">I am old enough to drive!</span>
          </li>
        </ol>
        <p class="mt-2 mb-0">* use ≥ comparison operators</p>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "I am old enough to drive!"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
