<template>
  <div>
    <h2>Conditional Expressions</h2>
    <p>
      A <strong>conditional expression</strong> evaluates to a boolean value of
      either <span class="block block-logic">true</span> or
      <span class="block block-logic">false</span>. Comparison operators below
      are use to create conditional expressions
    </p>

    <img
      class="img-fluid"
      src="@/assets/foundation/conditional_comparator.png"
    />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Fill in the blanks with any suitable value so that all the
            conditional expressions will be evaluated as
            <span class="block block-logic">true</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> true
          <br />
          >> true
          <br />
          >> true
          <br />
          >> true
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
