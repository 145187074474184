/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;
export const testCases = `
chaiAssert.isDefined(someNumber);
chaiAssert(typeof someNumber === "number", "someNumber should be a number");
chaiAssert.strictEqual(someNumber, 42);

chaiAssert.isDefined(someString);
chaiAssert(typeof someString === "string", "someString should be a string");
chaiAssert.strictEqual(someString, "forty-two");

chaiAssert.isDefined(isCorrect);
chaiAssert(typeof isCorrect === "boolean", "isCorrect should be a boolean");
chaiAssert.strictEqual(isCorrect, true);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;
  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
