<template>
  <div>
    <h2>For Loop Variable</h2>
    <p>
      Each for loop is created with a variable that works as a counter. In the
      block below, <span class="block block-variable">i</span> is the variable
      created with a value of zero. Every time the loop executes, it will be
      increased by 2 until it reaches 10.
    </p>
    <img class="img-fluid" src="@/assets/foundation/loop_variable_1.png" />

    <p>
      The variable <span class="block block-variable">i</span> can also be
      accessed and used in the loop. In the example below, the browser will
      print to screen with numbers 2, 4, 6, 8, 10.
    </p>
    <img class="img-fluid" src="@/assets/foundation/loop_variable_2.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Set the for loop so that the value will be as below</li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 3
          <br />
          >> 4
          <br />
          >> 5
          <br />
          >> 6
          <br />
          >> 7
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
