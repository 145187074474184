<template>
  <div class="default-layout">
    <navbar />
    <slot />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "DefaultLayout",

  components: {
    Navbar
  }
};
</script>

<style></style>
