/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;
export const testCases = `
  chaiAssert.isUndefined(undefinedVariable);
  chaiAssert(nullVariable === null, "nullVariable should be assigned with value of null");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;
  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
