<template>
  <div>
    <h2>If/Else if Statement</h2>
    <p>
      Else if statement adds another condition to check when the first if
      statement is false.
    </p>

    <p>
      In example below, the condition will first check if
      <span class="block block-variable">myNumber</span> is greater than 99.
    </p>
    <p>
      If not, it will check for second condition to see if
      <span class="block block-variable">myNumber</span> is greater than 10
    </p>
    <p>
      Finally if both conditions above are false, else statement will be
      executed
    </p>
    <img width="100%" src="@/assets/foundation/conditional_3.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <span class="block block-variable">myAge</span>,
            set it to 20
          </li>
          <li>
            Use a if statement, print a text saying
            <span class="block block-string">
              I am older than 50 years old!
            </span>
            if <span class="block block-variable">myAge</span> is greater than
            50.
          </li>
          <li>
            Print a text saying
            <span class="block block-string">
              I am older than 10 years old!
            </span>
            if <span class="block block-variable">myAge</span> is greater than
            10 but less than or equal 50.
          </li>
        </ol>
        <br />
        * Use only the > operator to solve this question
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "I am older than 10 years old!"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
