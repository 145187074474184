<template>
  <div class="empty-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "EmptyLayout"
};
</script>

<style></style>
