<template>
  <div>
    <h2>Exercise 1</h2>
    <h3>Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">myName</span> and assign it with
            a string of your name.
          </li>
          <li>
            Create a variable named
            <span class="block block-variable">favouriteAnimal</span> and assign
            it with a string of your favourite animal.
          </li>
          <li>
            Print the values of
            <span class="block block-variable">myName</span> and
            <span class="block block-variable">favouriteAnimal</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
