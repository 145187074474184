<template>
  <div>
    <h2>Exercise 5</h2>
    <p>
      A variable's value can also be assigned to another variable. Example:
    </p>
    <img src="@/assets/foundation/variable_reassign_2.png" />
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "Hello"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">mySecondNumber</span> and assign
            it with the value of
            <span class="block block-variable">myFirstNumber</span>.
          </li>
          <li>
            Print <span class="block block-variable">mySecondNumber</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 35
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
