import { ClassroomBuilder } from "./builders/classroom.builder";

export default class Classroom {
  id: string;
  name: string;
  classCode: string;
  organisationId: string;
  studentIds: string[];
  studentCount: number;
  courseCount: number;
  createdBy: string;
  isOpenToStudent: boolean;
  createdAt:
    | firebase.default.firestore.Timestamp
    | firebase.default.firestore.FieldValue;

  constructor(classroomBuilder: ClassroomBuilder) {
    this.id = classroomBuilder.getId();
    this.name = classroomBuilder.getName();
    this.classCode = classroomBuilder.getClassCode();
    this.organisationId = classroomBuilder.getOrganisationId();
    this.studentIds = classroomBuilder.getStudentIds();
    this.studentCount = classroomBuilder.getStudentCount();
    this.courseCount = classroomBuilder.getCourseCount();
    this.isOpenToStudent = classroomBuilder.isOpenToStudent();
    this.createdBy = classroomBuilder.getCreatedBy();
    this.createdAt = classroomBuilder.getCreatedAt();
  }

  getCreatedAtDatetime() {
    const createdAt = this.createdAt as firebase.default.firestore.Timestamp;
    return createdAt.toDate();
  }

  static fromDocumentData(
    id: string,
    data: firebase.default.firestore.DocumentData
  ) {
    return new ClassroomBuilder(id)
      .setName(data.name)
      .setClassCode(data.classCode)
      .setOrganisationId(data.organisationId)
      .setStudentIds(data.studentIds)
      .setStudentCount(data.studentCount)
      .setCourseCount(data.courseCount)
      .setIsOpenToStudent(data.isOpenToStudent)
      .setCreatedBy(data.createdBy)
      .setCreatedAt(data.createdAt)
      .build();
  }

  static converter = {
    toFirestore: function(classroom: Classroom) {
      return {
        name: classroom.name,
        organisationId: classroom.organisationId,
        createdBy: classroom.createdBy,
        createdAt: classroom.createdAt
      };
    },
    fromFirestore: function(
      snapshot: firebase.default.firestore.DocumentSnapshot,
      options: firebase.default.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options) ?? {};
      const id = snapshot.id;
      return Classroom.fromDocumentData(id, data);
    }
  };
}
