/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let sum = 0;

// Only write code below this line
`;

const seedTest = `
  chaiAssert(sum === 0, "Do not change the default code");
`;

export const testCases = `
  chaiAssert(sum === 500500, "Wrong sum value");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /for\s*\([\s\S]+\)\s*{[\s\S]+}/.test(code),
    "use a for loop to complete this activity"
  );

  window.console = oldConsole;
  return true;
}
