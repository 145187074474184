<template>
  <div>
    <h2>Compound Conditionals (AND)</h2>
    <p class="mb-0">
      A compound statement joined by AND evaluates two or more conditions before
      processing continues.
    </p>
    <img
      class="img-fluid"
      src="@/assets/foundation/conditional_and_block.png"
    />

    <p>
      This operator will combine two conditional expressions, and only returns
      true if both expressions are true
    </p>

    <p>
      In example below, the conditional expression is only true when both
      <span class="block block-variable">number</span> and
      <span class="block block-variable">secondNum</span> are greater than 50
    </p>
    <img
      class="img-fluid"
      src="@/assets/foundation/conditional_and_example.png"
    />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> false
          <br />
          >> false
          <br />
          >> false
          <br />
          >> true
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create
            <span class="block block-variable">firstNum</span> and
            <span class="block block-variable">secondNum</span> variable and set
            them to any number
          </li>
          <li>
            Use an if statement, print
            <span class="block block-string">Both are more than 10!</span> if
            <span class="block block-variable">firstNum</span> and
            <span class="block block-variable">secondNum</span> are more than 10
          </li>
          <li>
            Else, print
            <span class="block block-string">
              Either one or both not more than 10!
            </span>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
