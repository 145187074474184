/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let weight = 60;
let height = 1.7;

// Only write your code below this line
`;

const seedTest = `
  chaiAssert.isDefined(weight);
  chaiAssert(typeof weight === "number", "Do not change the default code");
  chaiAssert.strictEqual(weight, 60);

  chaiAssert.isDefined(height);
  chaiAssert(typeof height === "number", "Do not change the default code");
  chaiAssert.strictEqual(height, 1.7);
`;

export const testCases = `
  chaiAssert.isDefined(bmi);
  chaiAssert(typeof bmi === "number", "bmi should be a number");
  chaiAssert.strictEqual(bmi, weight / height / height);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
