<template>
  <div>
    <h2>Combined Operators</h2>
    <p>
      We can combine more than one math operator to perform a more complex
      calculation. Computer math operation follows convention math precedence
      BODMAS that is
    </p>
    <ol>
      <li>Parentheses</li>
      <li>Exponents</li>
      <li>Multiplication and Division</li>
      <li>Addition and Subtraction</li>
    </ol>
    <p>
      Example combining operators
    </p>
    <img class="img-fluid" src="@/assets/foundation/combined_operators.png" />
    <p>Equals to (20 + 15) + (5 x 7)</p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">firstNumber</span> and set it to
            a value of 150
          </li>
          <li>
            Create a variable
            <span class="block block-variable">answer</span> and set it to a
            value of <span class="block block-variable">firstNumber</span> + 20
            - 79
          </li>
          <li>Print <span class="block block-variable">answer</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 91
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
