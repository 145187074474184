import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

// Firebase production
firebase.initializeApp({
  apiKey: "AIzaSyDRZY43m7dc76Npq-jXHB5xSVa_QZ2vyVo",
  authDomain: "codehive-v2.firebaseapp.com",
  projectId: "codehive-v2",
  storageBucket: "codehive-v2.appspot.com",
  messagingSenderId: "457460398485",
  appId: "1:457460398485:web:f75ecdbc8bfb9fef641e9c",
  measurementId: "G-ZT2G9KTNEQ"
});
firebase.analytics();

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage().ref();
export const functions = firebase.functions();
export const firestoreFieldValue = firebase.firestore.FieldValue;
export const firestoreFieldPath = firebase.firestore.FieldPath;
