<template>
  <div>
    <h2>Exercise 25</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Using nested for loop, print each of the following value in below
            sequence
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 1
          <br />
          >> 1
          <br />
          >> 1
          <br />
          >> 1
          <br />
          >> 2
          <br />
          >> 2
          <br />
          >> 2
          <br />
          >> 2
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
