export default class Organisation {
  id: string;
  name: string;
  contact: string;
  email: string;
  addressOne: string;
  addressTwo: string;
  studentCount: string;
  plan: string;
  availableLicenses: number;
  remainingLicenses: number;
  subscriptionId: string;
  subscriptionStart: Date | null;
  subscriptionEnd: Date | null;
  roles: Record<string, string>;

  constructor(id: string, data: firebase.default.firestore.DocumentData) {
    this.id = id;
    this.roles = data.roles;
    this.name = data.name;
    this.contact = data.contact;
    this.email = data.email;
    this.studentCount = data.studentCount ?? 0;
    this.addressOne = data.addressOne;
    this.addressTwo = data.addressTwo;
    this.plan = data.plan;
    this.availableLicenses = data.availableLicenses;
    this.remainingLicenses = data.remainingLicenses;
    this.subscriptionId = data.subscriptionId;

    const subscriptionStart =
      data.subscriptionStart == null ? null : data.subscriptionStart.toDate();
    const subscriptionEnd =
      data.subscriptionEnd == null ? null : data.subscriptionEnd.toDate();

    this.subscriptionStart = subscriptionStart;
    this.subscriptionEnd = subscriptionEnd;
  }

  static converter = {
    toFirestore: function() {
      return {};
    },
    fromFirestore: function(
      snapshot: firebase.default.firestore.DocumentSnapshot,
      options: firebase.default.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options) ?? {};
      const id = snapshot.id;
      return new Organisation(id, data);
    }
  };
}
