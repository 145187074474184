<template>
  <div>
    <h2>Exercise 27</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use a while loop to print out all the odd numbers from 155 to 255
          </li>
        </ol>

        <p class="mb-0 mt-2">
          *Use either &lt; or ≤ operator to solve this activity
        </p>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 155
          <br />
          >> 157
          <br />
          >> .
          <br />
          >> .
          <br />
          >> 255
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
