import { firestore, firestoreFieldValue, storage } from "@/config/firebase";
import Course from "@/models/course.model";

export function getCourses() {
  return firestore
    .collection("courses")
    .where("status", "in", ["live"])
    .withConverter(Course.converter)
    .get()
    .then(querySnapshots => {
      const courses: Course[] = [];
      querySnapshots.forEach(snapshot => {
        courses.push(snapshot.data());
      });
      return courses;
    });
}

export function getCoursesByClassroom(classroomId: string) {
  return firestore
    .collection("classrooms")
    .doc(classroomId)
    .collection("classroomCourses")
    .withConverter(Course.converter)
    .get()
    .then(querySnapshots => {
      const courses: Course[] = [];
      querySnapshots.forEach(snapshot => {
        courses.push(snapshot.data());
      });
      return courses;
    });
}

export function getCoursesByOrganisation(organisationId: string) {
  return firestore
    .collection("courses")
    .where("organisationId", "==", organisationId)
    .withConverter(Course.converter)
    .get()
    .then(querySnapshots => {
      const courses: Course[] = [];
      querySnapshots.forEach(snapshot => {
        courses.push(snapshot.data());
      });
      return courses;
    });
}

export function getCourse(id: string) {
  return firestore
    .collection("courses")
    .doc(id)
    .withConverter(Course.converter)
    .get()
    .then(snapshot => {
      return snapshot.data();
    });
}

export function addClassroom(courseId: string, classroomId: string) {
  return firestore
    .collection("courses")
    .doc(courseId)
    .update({
      classroomIds: firestoreFieldValue.arrayUnion(classroomId)
    });
}

export function createCourse(course: Course) {
  return firestore
    .collection("courses")
    .withConverter(Course.converter)
    .add(course);
}

export function updateCourse(courseId: string, update: object) {
  return firestore
    .collection("courses")
    .doc(courseId)
    .update(update);
}

export function deleteCourse(courseId: string) {
  return firestore
    .collection("courses")
    .doc(courseId)
    .delete();
}

export async function uploadFile(courseId: string, file: File) {
  const uploadRef = storage.child(`courses/${courseId}/${file.name}`);

  try {
    await uploadRef.put(file);
  } catch (error) {
    alert(error.message);
  }

  return uploadRef.getDownloadURL();
}
