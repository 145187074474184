/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;

const seedTest = `
`;

export const testCases = `
  chaiAssert.isDefined(randomNumber);
  chaiAssert(Number.isInteger(randomNumber), "randomNumber should be an integer");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);

  assert(
    /let\s+randomNumber\s*=\s*Math.random\(\)\s*\*\s*100\s*;|let\s+randomNumber\s*=\s*100\s*\*\s*Math.random\(\)\s*;/.test(
      code
    ),
    "assign randomNumber with Math.random() multiply by 100"
  );

  assert(
    /randomNumber\s*=\s*Math.round\(\s*randomNumber\s*\)\s*;/.test(code),
    "round randomNumber with Math.round() methods"
  );

  window.console = oldConsole;
  return true;
}
