/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let specialNumber = 12;

// Only write your code below this line
`;

const seedTest = `
  chaiAssert(specialNumber === 12, "Do not change the default code");
`;

export const testCases = `
  chaiAssert.isDefined(specialNumber);
  chaiAssert(typeof specialNumber === "number", "specialNumber should be a number");
  chaiAssert.strictEqual(specialNumber, 65);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
