/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let someNumber = 123;

// Only write your code below this line
`;

const seedTest = `
  chaiAssert(someNumber === 123, "Do not change the default code");
`;

export const testCases = `
  chaiAssert.isDefined(otherNumber);
  chaiAssert(typeof otherNumber === "number", "otherNumber should be a number");
  chaiAssert.strictEqual(otherNumber, 123);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);

  assert(
    /let\s+otherNumber\s*=\s*someNumber\s*;/.test(code),
    "assign value using someNumber"
  );

  window.console = oldConsole;
  return true;
}
