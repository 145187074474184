<template>
  <div>
    <h2>Exercise 18</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use an if statement, print a text
            <span class="block block-string">
              It is an odd number or greater than 50!
            </span>
            if <span class="block block-variable">randomNum</span> is an odd
            number <strong>OR</strong>
            it is greater than 50
          </li>
          <li>
            Else, print
            <span class="block block-string">
              It is not!
            </span>
          </li>
        </ol>
        <p class="mt-2 mb-0">
          *Use only the ≠ not equal operator to solve this activity
        </p>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
