/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let fruit = "orange";
let message;

function checkFruit() {
// Only write code below this line




// Only write code above this line
return message;
}`;

const seedTest = `
  chaiAssert(fruit === "orange", "Do not change the default code");
  chaiAssert.isUndefined(message, "Do not change the default code");
`;

export const testCases = `
  checkFruit();
  chaiAssert(message === "It is not", "Wrong message value when fruit not equal 'apple'");

  fruit = "apple";
  checkFruit();
  chaiAssert(message === "It is apple", "Wrong message value when fruit equals 'apple'");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /if\s*\([\s\S]+\)\s*{*[\s\S]+}\s*else\s*{*[\s\S]+}/.test(code),
    "use an if/else statement"
  );

  eval(code + testCases);

  window.console = oldConsole;
  return true;
}
