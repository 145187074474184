<template>
  <div>
    <h2>Introduction to Variable</h2>
    <p>
      In a program, we need to store pieces of information so that we can reuse
      them throughout our program if necessary. <strong>Variables</strong> are
      used to store this information.
    </p>
    <p>
      You can think of variables as containers that hold information. We need to
      name the variables created so that we can retrieve the correct
      information.
    </p>
    <p>
      In Blockly, you can create a variable with the "create variable" button
      and use the set block to assign a value to that variable.
    </p>
    <p class="mb-0">
      The example below shows a variable named
      <span class="block block-variable">someVariable</span> and is assigned a
      string <span class="block block-string">Hello!</span> into the variable.
    </p>
    <img class="img-fluid" src="@/assets/foundation/variable_one.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">myText</span> and assign it with
            a string (text) of
            <span class="block block-string">Hello World!</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
