<template>
  <div>
    <h2>Exercise 16</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <span class="block block-variable">number</span>,
            set it to 60
          </li>
          <li>
            If <span class="block block-variable">number</span> is greater than
            80:<br />
            Print a text saying
            <span class="block block-string">
              More than 80!
            </span>
          </li>
          <li>
            Else if <span class="block block-variable">number</span> is greater
            than 50 and less than or equal 80:<br />
            Print a text saying
            <span class="block block-string">
              More than 50!
            </span>
          </li>
          <li>
            Else if <span class="block block-variable">number</span> is greater
            than 25 and less than or equal 50:<br />
            Print a text saying
            <span class="block block-string">
              More than 25!
            </span>
          </li>
          <li>
            For <span class="block block-variable">number</span> with any other
            value Print a text saying
            <span class="block block-string">
              Less than or equal 25!
            </span>
          </li>
        </ol>
        <p class="mb-0 mt-2">
          * Use only > operator
        </p>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "More than 50!"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
