import { UserBuilder } from "@/models/builders/user.builder";

export default class User {
  id: string;
  role: string;
  name: string;
  email: string;
  stripeCustomerId: string;
  emailVerified: boolean;
  organisationId: string;

  constructor(userBuilder: UserBuilder) {
    this.id = userBuilder.getId();
    this.name = userBuilder.getName();
    this.role = userBuilder.getRole();
    this.stripeCustomerId = userBuilder.getCustomerId();
    this.email = userBuilder.getEmail();
    this.emailVerified = userBuilder.getEmailVerified();
    this.organisationId = userBuilder.getOrganisationId();
  }

  // User Factories
  static fromFirebaseUser(user: firebase.default.User) {
    return new UserBuilder(user.uid).setEmail(user.email || "").build();
  }

  static fromDocumentData(
    id: string,
    data: firebase.default.firestore.DocumentData
  ) {
    return new UserBuilder(id)
      .setName(data.name)
      .setEmail(data.email)
      .setCustomerId(data.stripeCustomerId)
      .setRole(data.role)
      .setOrganisationId(data.organisationId)
      .build();
  }

  static converter = {
    toFirestore: function(user: User) {
      return {
        name: user.name,
        email: user.email,
        role: user.role
      };
    },
    fromFirestore: function(
      snapshot: firebase.default.firestore.DocumentSnapshot,
      options: firebase.default.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options) || {};
      const id = snapshot.id;
      return User.fromDocumentData(id, data);
    }
  };
}
