import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user.store";
import organisation from "./modules/organisation.store";
import game from "./modules/game.store";

import { Commit } from "vuex";
import { userServices, organisationServices } from "@/services";
import { StoreState } from "./store.interface";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {} as StoreState,

  mutations: {},

  actions: {
    async login({ commit }: { commit: Commit }, id: string) {
      try {
        const user = await userServices.getUser(id);
        commit("user/update", user);

        if (user.role == "student") {
          return;
        } else {
          const organisation = await organisationServices.getOrganisation(
            user.organisationId
          );

          return new Promise<void>(resolve => {
            commit("organisation/update", organisation);
            resolve();
          });
        }
      } catch (error) {
        alert(error.message);
      }
    },

    logout({ commit }: { commit: Commit }) {
      return new Promise<void>(resolve => {
        commit("user/reset", user);
        commit("organisation/reset", organisation);
        resolve();
      });
    }
  },

  modules: {
    user,
    organisation,
    game
  }
});
