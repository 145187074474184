/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;

const seedTest = `
`;

export const testCases = `
  chaiAssert(naturalSum(0) === 0 , "function should return 0 when argument is 0");
  chaiAssert(naturalSum(5) === 15 , "function should return 15 when argument is 5");
  chaiAssert(naturalSum(80) === 3240 , "function should return 3240 when argument is 80");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  const seedCode = "";
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /function naturalSum\([\s\S]+\)\s*{[\s\S]+}/.test(code),
    "naturalSum function not declared"
  );

  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
