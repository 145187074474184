/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;

const seedTest = `
`;

export const testCases = `
  chaiAssert.isDefined(number);
  chaiAssert(typeof number === "number", "number should be a number");
  chaiAssert.strictEqual(number, 5);

  chaiAssert.isDefined(numberString);
  chaiAssert(typeof numberString === "string", "numberString should be a string");
  chaiAssert.strictEqual(numberString, "5");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
