<template>
  <div>
    <h2>Function with Parameter</h2>
    <p>
      We can also pass data into a function, the data we pass into a function is
      called a <span class="block block-variable">parameter</span>. We can pass
      multiple parameters into a function
    </p>
    <img class="img-class" src="@/assets/foundation/function_two_1.png" />
    <p>
      The <span class="block block-variable">sayGreeting</span> function has a
      parameter
      <span class="block block-variable">name</span>
    </p>
    <img class="img-class" src="@/assets/foundation/function_two_2.png" />
    <p>
      We can call <span class="block block-variable">sayGreeting</span> function
      by input a string as its argument.
    </p>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "Hello John"
        </code>
      </b-card-text>
    </b-card>
    <p class="mt-3">
      The term <strong>parameter</strong> is used to identify the placeholders
      in the function, whereas the term <strong>arguments</strong> are the
      actual values that you pass in to the function.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a function
            <span class="block block-variable">sayMyName</span> with a
            <span class="block block-variable">name</span> parameter
          </li>
          <li>
            The function will print a messages "My name is
            <span class="block block-variable">name</span>"
          </li>
          <li>
            Call <span class="block block-variable">sayMyName</span> function
            with your name as an argument
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Example result:
          <br />
          >> "My name is John"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
