import { auth } from "@/config/firebase";

export function signupNewUser(email: string, password: string) {
  return auth.createUserWithEmailAndPassword(email, password);
}

export function loginUser(email: string, password: string) {
  return auth.signInWithEmailAndPassword(email, password);
}

export function getCurrentUser(): Promise<firebase.default.User | null> {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export function signoutUser() {
  return auth.signOut();
}

export function forgetPassword(email: string) {
  return auth.sendPasswordResetEmail(email);
}
