import User from "@/models/user.model";

export class UserBuilder {
  private _id = "";
  private _role = "";
  private _name = "";
  private _email = "";
  private _stripeCustomerId = "";
  private _emailVerified = false;
  private _organisationId = "";

  constructor(id: string) {
    this._id = id;
  }

  setRole(role: string) {
    this._role = role;
    return this;
  }

  setName(name: string) {
    this._name = name;
    return this;
  }

  setEmail(email: string) {
    this._email = email;
    return this;
  }

  setCustomerId(id: string) {
    this._stripeCustomerId = id;
    return this;
  }

  setEmailVerified(emailVerified: boolean) {
    this._emailVerified = emailVerified;
    return this;
  }

  setOrganisationId(id: string) {
    this._organisationId = id;
    return this;
  }

  build() {
    return new User(this);
  }

  getId() {
    return this._id;
  }

  getRole() {
    return this._role;
  }

  getName() {
    return this._name;
  }

  getCustomerId() {
    return this._stripeCustomerId;
  }

  getEmail() {
    return this._email;
  }

  getEmailVerified() {
    return this._emailVerified;
  }

  getOrganisationId() {
    return this._organisationId;
  }
}
