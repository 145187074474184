/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let numbers = [];
for (let i = 1; i < 6; i++) {

  // Only write code below this line



  // Only write code above this line
  numbers.push(i);
}
console.log(numbers);`;

const seedTest = `
  chaiAssert.deepEqual(numbers, [], "Do not change default code");
`;

export const testCases = `
  let answer = [1, 2, 4, 5];
  chaiAssert.deepEqual(numbers, answer, "Wrong numbers inserted into array");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  seedCode += "}";
  eval(seedCode + seedTest);
  assert(
    /for \(let i = 1; i < 6; i\+\+\)/.test(seedCode),
    "Do not change default code"
  );

  window.console = customConsole(self);

  eval(code + testCases);

  window.console = oldConsole;
  return true;
}
