/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let someArray = [7, 56, 24, 18, 33];

// Only write code below this line
`;

const seedTest = `
  chaiAssert.deepEqual(someArray, [7, 56, 24, 18, 33], "Do not change default code");
`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);

  assert(
    /console\.log\(\s*someArray\.length\s*\)\s*;/.test(code),
    "use .length property to get number of elements"
  );

  window.console = oldConsole;
  return true;
}
