/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;

const seedTest = `
`;

export const testCases = `
  chaiAssert(isMoreThanFifty([51]) === true, "function should return true when argument is [51]");

  chaiAssert(isMoreThanFifty([10]) === false, "function should return true when argument is [10]");

  chaiAssert(isMoreThanFifty([1,2,3]) === false, "function should return true when argument is [1,2,3]");

  chaiAssert(isMoreThanFifty([15,33,22]) === true, "function should return true when argument is [15,33,22]");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  const seedCode = "";
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /function isMoreThanFifty\([\s\S]+\)\s*{[\s\S]+}/.test(code),
    "isMoreThanFifty function not declared"
  );

  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
