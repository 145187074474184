/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `console.log(2 + 2);
console.log(4 - 2);
console.log(3 * 2);
console.log(8 / 4);
console.log(10 % 3);
`;

const seedTest = `
`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
