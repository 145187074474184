<template>
  <div>
    <h2>Exercise 9</h2>
    <p>
      Each operator block can be seen as a bracket. Hence, the arrangement of
      blocks would lead to different calculation sequence. For example:
    </p>
    <img
      class="img-fluid mb-2"
      src="@/assets/foundation/operator_precedence.png"
    />

    <p>
      First statement: (1 + 2) x 3
      <br />
      Second statement: 1 + (2 x 3)
    </p>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 9
          <br />
          >> 7
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">answer</span> and set it to the
            value of 22 + 3 x 4
          </li>
          <li>Print <span class="block block-variable">answer</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 34
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
