/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let a = 20;
let result;

function checkResult() {
// Only rewrite code below this line

  if (a > 10) {
    result = 1;
  } else {
    result = 0;
  }

// Only rewrite code above this line
}`;

const seedTest = `
  chaiAssert(result === undefined, "Do not change the default code");
  chaiAssert(a === 20, "Do not change the default code");
`;

export const testCases = `
  checkResult();
  chaiAssert(result === 1, "Wrong result when condition is true");

  a = 0;
  checkResult();
  chaiAssert(result === 0, "Wrong result when condition is false");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /result\s*=\s*a\s*>\s*10\s*\?\s*1\s*:\s*0\s*;/.test(code),
    "use tenary operator to assign result variable"
  );

  eval(code + testCases);

  window.console = oldConsole;
  return true;
}
