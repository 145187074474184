<template>
  <div>
    <b-row no-gutters>
      <b-col md="6" class="bg-dark"></b-col>
      <b-col md="6">
        <div
          class="login-panel d-flex justify-content-center align-items-center flex-column"
        >
          <h3 class="mb-3">Sign up</h3>
          <div class="login-button-container">
            <b-form @submit.prevent="onClickSignUp">
              <b-form-group label="Account type">
                <b-form-radio-group v-model="role" required>
                  <b-form-radio value="student">
                    Student
                  </b-form-radio>
                  <b-form-radio value="teacher">
                    Teacher
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="name"
                  type="text"
                  placeholder="Name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="email"
                  type="email"
                  placeholder="Email"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="password"
                  type="password"
                  placeholder="Password"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  required
                ></b-form-input>
              </b-form-group>
              <div>
                <b-button
                  block
                  class="login-button"
                  variant="success"
                  type="submit"
                  :disabled="isSigningUp"
                >
                  <b-spinner small v-if="isSigningUp"></b-spinner>
                  {{ isSigningUp ? "Loading..." : "Join Now" }}
                </b-button>
              </div>
            </b-form>
          </div>
          <div class="mt-2">
            Already an user? <router-link to="/login">Login</router-link>
          </div>
          <div class="error-message mt-3">
            {{ errorMessage }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { authServices, userServices, organisationServices } from "@/services";
import { firestoreFieldValue } from "@/config/firebase";
import moment from "moment";

export default {
  name: "Login",

  data() {
    return {
      classroomId: this.$route.query.cl,
      role: "",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      errorMessage: "",
      isSigningUp: false
    };
  },

  methods: {
    async onClickSignUp() {
      this.isSigningUp = true;

      if (this.password != this.confirmPassword) {
        this.errorMessage = "Confirm password is not same as password";
        this.isSigningUp = false;
        return;
      }

      if (this.role == "student") {
        this.signUpStudent();
      } else if (this.role == "teacher") {
        this.signUpTeacher();
      }
    },

    async signUpStudent() {
      try {
        // Sign up user
        const userCredential = await authServices.signupNewUser(
          this.email,
          this.password
        );
        const user = userCredential.user;

        // Add user
        await userServices.addUser({
          id: user.uid,
          name: this.name,
          email: user.email,
          emailVerified: user.emailVerified,
          role: this.role
        });

        this.$analytics.logEvent("sign_up");
        this.$store.commit("user/update", user);
        this.afterLogin();
      } catch (error) {
        this.errorMessage = error.message;
        this.isSigningUp = false;
      }
    },

    async signUpTeacher() {
      try {
        // Sign up user
        const userCredential = await authServices.signupNewUser(
          this.email,
          this.password
        );
        const user = userCredential.user;

        // Add organisation
        const organisationRef = await organisationServices.addOrganisation({
          plan: "free",
          createdAt: firestoreFieldValue.serverTimestamp(),
          subscriptionStart: firestoreFieldValue.serverTimestamp(),
          subscriptionEnd: moment()
            .add(14, "days")
            .toDate(),
          availableLicenses: 5,
          remainingLicenses: 5,
          roles: {
            [user.uid]: "teacher"
          }
        });

        // Add user
        await userServices.addUser({
          id: user.uid,
          name: this.name,
          email: user.email,
          emailVerified: user.emailVerified,
          role: this.role,
          organisationId: organisationRef.id
        });

        this.$store.commit("user/update", user);
        this.$router.push("/dashboard");
      } catch (error) {
        this.errorMessage = error.message;
        this.isSigningUp = false;
      }
    },

    afterLogin() {
      if (this.classroomId != null) {
        this.$router.push(`/invite/${this.classroomId}`);
      } else {
        this.$router.push("/dashboard");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.login-panel {
  background-color: white;
  height: 100vh !important;
}

@media (max-width: 768px) {
  .login-button-container {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .login-button-container {
    width: 50%;
  }
}

.error-message {
  width: 50%;
  color: red;
  text-align: center;
}
</style>
