/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = ``;
export const testCases = ``;

export function testCorrectness(code: string, self: any) {
  const oldConsole = window.console;
  window.console = customConsole(self);
  eval(code);
  assert(
    /console\.log\(\s*"Hello World!"\s*\)\s*;/.test(code),
    'Print "Hello World!" using console.log'
  );
  window.console = oldConsole;
  return true;
}
