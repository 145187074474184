import User from "@/models/user.model";

const user = {
  namespaced: true,

  state: {
    id: null,
    email: null,
    name: null,
    role: null,
    stripeCustomerId: null
  },

  getters: {
    state(state: Record<string, unknown>) {
      return state;
    },

    getRole(state: Record<string, unknown>) {
      return state.role;
    }
  },

  mutations: {
    update(state: Record<string, unknown>, user: User) {
      state.id = user.id;
      state.email = user.email;
      state.name = user.name;
      state.role = user.role;
      state.stripeCustomerId = user.stripeCustomerId;
    },

    reset(state: Record<string, unknown>) {
      state.id = null;
      state.email = null;
      state.name = null;
      state.role = null;
      state.stripeCustomerId = null;
    }
  },

  actions: {}
};

export default user;
