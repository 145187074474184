<template>
  <div>
    <h2>Introduction to Conditionals</h2>
    <p>
      An if statement enables a program to make decisions whether it should run
      certain codes based on a condition. We can do things conditionally in our
      programs using if statements and if/else statements combined with
      conditional expressions.
    </p>
    <p>
      An if statement tells the program to execute a block of code if a
      condition expression is <span class="block block-logic">true</span>.
    </p>

    <p class="mb-2">
      In the example below, the message
      <span class="block block-string">Hello</span> will only be printed if
      myNumber is more than 10
    </p>

    <img class="img-fluid" src="@/assets/foundation/conditional_if_2.png" />

    <p class="mb-2">
      Below is the
      <strong>conditional expression</strong> that evaluates to a boolean value
      of either true or false.
    </p>

    <img
      class="img-fluid mb-3"
      src="@/assets/foundation/conditional_expression.png"
    />
    <p>
      Since, <span class="block block-variable">myNumber</span> is more than 10,
      the expression will be evaluated as
      <span class="block block-logic">true</span>, the message
      <span class="block block-string">Hello</span> will be printed.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Change the value of
            <span class="block block-variable">number</span> so that the print
            statement will be execute.
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "Hello!"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
