/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let car = {
  colour: "red",
  wheels: 4,
  type: "suv",
  maxSpeed: 300
};

// Only write code below this line
`;

const seedTest = `
  let answer = {
    colour: "red",
    wheels: 4,
    type: "suv",
    maxSpeed: 300
  };
  chaiAssert.deepEqual(car, answer, "Do not change default code");
`;

export const testCases = `
  chaiAssert.deepEqual(keys, ["colour", "wheels", "type", "maxSpeed"], 'keys should be equal to ["colour", "wheels", "type", "maxSpeed"]');
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 6; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /keys\s*=\s*Object.keys\(car\)\s*;/.test(code),
    "use Object.keys() method"
  );
  window.console = oldConsole;
  return true;
}
