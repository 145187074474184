<template>
  <div>
    <b-navbar toggleable="md" variant="white" v-if="isLoggedIn">
      <b-navbar-brand to="/">
        <img
          src="../assets/csplayground-logo.png"
          alt="codehive logo"
          height="40"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/dashboard">Dashboard</b-nav-item>

          <b-nav-item v-if="isTeacher" to="/courses">Courses</b-nav-item>

          <b-nav-item-dropdown right>
            <template #button-content>Playgrounds</template>
            <b-dropdown-item to="/playground/blockly">
              Blockly
            </b-dropdown-item>
            <b-dropdown-item to="/playground/javascript">
              JavaScript
            </b-dropdown-item>
            <b-dropdown-item to="/playground/python">
              Python
            </b-dropdown-item>
            <b-dropdown-item to="/playground/sql">
              SQL
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ $store.state.user.email }}</em>
            </template>
            <b-dropdown-item to="/setting">Setting</b-dropdown-item>
            <b-dropdown-item @click="onClickSignout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item v-if="isFreePlanTeacher">
            <b-button
              to="/plans"
              variant="warning"
              size="sm"
              class="my-2 my-sm-0"
            >
              Get Licenses
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-navbar toggleable="md" variant="white" v-else>
      <b-navbar-brand to="/">
        <img
          src="../assets/csplayground-logo.png"
          alt="codehive logo"
          height="40"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/login">Login</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import * as authServices from "@/services/auth.service";

export default {
  name: "Navbar",

  methods: {
    onClickSignout() {
      if (confirm("Confirm sign out?")) {
        return authServices.signoutUser().then(() => {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        });
      }
    }
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.user.id != null;
    },

    isTeacher() {
      return this.$store.state.user.role == "teacher";
    },

    isFreePlanTeacher() {
      return (
        this.$store.state.user.role == "teacher" &&
        this.$store.state.organisation.plan == "free"
      );
    }
  }
};
</script>

<style lang="less" scoped>
.navbar {
  border-bottom: 1px solid rgb(218, 218, 218) !important;
}
</style>
