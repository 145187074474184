import { firestore, firestoreFieldValue } from "@/config/firebase";
import Classroom from "@/models/classroom.model";
import Course from "@/models/course.model";
import ClassroomInterface from "@/models/interfaces/classroom.interface";
import User from "@/models/user.model";

export function addClassroom(classroom: Classroom) {
  return firestore
    .collection("classrooms")
    .withConverter(Classroom.converter)
    .add(classroom);
}

export function getClassroom(id: string) {
  return firestore
    .collection("classrooms")
    .doc(id)
    .withConverter(Classroom.converter)
    .get()
    .then(snapshot => {
      return snapshot.data();
    });
}

export function getClassroomByClasscode(classCode: string) {
  return firestore
    .collection("classrooms")
    .where("classCode", "==", classCode)
    .withConverter(Classroom.converter)
    .get()
    .then(querySnapshot => {
      const classrooms: Classroom[] = [];
      querySnapshot.forEach(snapshot => {
        const classroom = snapshot.data();
        classrooms.push(classroom);
      });
      return classrooms[0];
    });
}

export function getTeacherClassrooms(userId: string) {
  return firestore
    .collection("classrooms")
    .where("createdBy", "==", userId)
    .withConverter(Classroom.converter)
    .get()
    .then(querySnapshot => {
      const classrooms: Classroom[] = [];
      querySnapshot.forEach(snapshot => {
        const classroom = snapshot.data();
        classrooms.push(classroom);
      });
      return classrooms;
    });
}

export function getStudentClassrooms(studentId: string) {
  return firestore
    .collection("classrooms")
    .where("studentIds", "array-contains", studentId)
    .withConverter(Classroom.converter)
    .get()
    .then(querySnapshot => {
      const classrooms: Classroom[] = [];
      querySnapshot.forEach(snapshot => {
        const classroom = snapshot.data();
        classrooms.push(classroom);
      });
      return classrooms;
    });
}

export function getClassrooms() {
  return firestore
    .collection("classrooms")
    .withConverter(Classroom.converter)
    .get()
    .then(querySnapshot => {
      const classrooms: Classroom[] = [];
      querySnapshot.forEach(snapshot => {
        const classroom = snapshot.data();
        classrooms.push(classroom);
      });
      return classrooms;
    });
}

export function updateClassroom(
  classroomId: string,
  classroom: ClassroomInterface
) {
  return firestore
    .collection("classrooms")
    .doc(classroomId)
    .update(classroom);
}

export function addCourse(classroomId: string, course: Course) {
  return firestore
    .collection("classrooms")
    .doc(classroomId)
    .collection("classroomCourses")
    .doc(course.id)
    .withConverter(Course.converter)
    .set(course);
}

export function deleteCourse(classroomId: string, courseId: string) {
  return firestore
    .collection("classrooms")
    .doc(classroomId)
    .collection("classroomCourses")
    .doc(courseId)
    .delete();
}

export function addStudent(classroomId: string, studentId: string) {
  return firestore
    .collection("classrooms")
    .doc(classroomId)
    .update({ studentIds: firestoreFieldValue.arrayUnion(studentId) });
}

export function deleteStudent(classroomId: string, studentId: string) {
  return firestore
    .collection("classrooms")
    .doc(classroomId)
    .update({ studentIds: firestoreFieldValue.arrayRemove(studentId) });
}

export function getClassroomStudents(classroomId: string) {
  return firestore
    .collection("users")
    .where("classroomIds", "array-contains", classroomId)
    .withConverter(User.converter)
    .get()
    .then(querySnapshot => {
      const users: User[] = [];
      querySnapshot.forEach(snapshot => {
        const user = snapshot.data();
        users.push(user);
      });
      return users;
    });
}

export function deleteClassroom(classroomId: string) {
  return firestore
    .collection("classrooms")
    .doc(classroomId)
    .delete();
}
