import { GameState } from "../store.interface";

const game = {
  namespaced: true,

  state: {
    hasProgramStarted: false,
    hasReachEndPoint: false,
    isRunning: false,
    isMovingRight: false,
    isMovingLeft: false,
    isMovingUp: false,
    isMovingDown: false,
    isAttackingUp: false,
    isAttackingDown: false,
    isAttackingLeft: false,
    isAttackingRight: false,
    itemUp: null,
    itemDown: null,
    itemLeft: null,
    itemRight: null,
    slashCount: 0,
    killCount: 0
  },

  getters: {
    isInAction(state: GameState) {
      return (
        state.isMovingUp ||
        state.isMovingDown ||
        state.isMovingLeft ||
        state.isMovingRight ||
        state.isAttackingUp ||
        state.isAttackingDown ||
        state.isAttackingLeft ||
        state.isAttackingRight
      );
    },

    isAttacking(state: GameState) {
      return (
        state.isAttackingUp ||
        state.isAttackingDown ||
        state.isAttackingLeft ||
        state.isAttackingRight
      );
    },

    hasProgramEnded(state: GameState) {
      return state.hasProgramStarted && !state.isRunning;
    }
  },

  mutations: {
    stopAllMovement(state: GameState) {
      state.isMovingRight = false;
      state.isMovingLeft = false;
      state.isMovingUp = false;
      state.isMovingDown = false;
    },

    resetState(state: GameState) {
      state.hasProgramStarted = false;
      state.hasReachEndPoint = false;
      state.isMovingRight = false;
      state.isMovingLeft = false;
      state.isMovingUp = false;
      state.isMovingDown = false;
      state.isAttackingUp = false;
      state.isAttackingDown = false;
      state.isAttackingLeft = false;
      state.isAttackingRight = false;
      state.itemUp = null;
      state.itemDown = null;
      state.itemLeft = null;
      state.itemRight = null;
      state.slashCount = 0;
      state.killCount = 0;
    },

    addKillCount(state: GameState) {
      state.killCount++;
    },

    startGame(state: GameState) {
      state.hasProgramStarted = true;
    },

    reachEndPoint(state: GameState) {
      state.hasReachEndPoint = true;
    }
  },

  actions: {}
};

export default game;
