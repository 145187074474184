/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let number = 8;

if (number == 10) {
  // This line will not be executed
  console.log("It is 10");
}

if (number == 8) {
  // This line will be executed
  console.log("It is 8");
}
`;

const seedTest = `
`;

export const testCases = `
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
