<template>
  <div class="home">
    <component :is="dashboardComponent" />
  </div>
</template>

<script>
import TeacherDashboard from "@/views/dashboards/TeacherDashboard.vue";
import StudentDashboard from "@/views/dashboards/StudentDashboard.vue";

export default {
  name: "Home",

  data() {
    return {
      userRole: this.$store.state.user.role
    };
  },

  computed: {
    dashboardComponent() {
      let component = null;
      switch (this.$store.state.user.role) {
        case "teacher":
          component = TeacherDashboard;
          break;

        case "student":
          component = StudentDashboard;
          break;

        default:
          break;
      }
      return component;
    }
  }
};
</script>
