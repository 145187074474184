<template>
  <div class="home">
    <b-container class="my-5">
      <b-row>
        <!-- Student Classes -->
        <b-col md="4">
          <div class="d-flex justify-content-between align-items-center">
            <h2>👩‍🎓 Your Classes</h2>
            <div>
              <b-button variant="dark" size="sm" v-b-modal.modal-join-class>
                Join Class
              </b-button>
            </div>
          </div>

          <p class="mt-2" v-if="classrooms.length == 0 && !isLoading">
            You are not enrolled in any class 🥺
          </p>

          <b-card
            v-for="classroom in classrooms"
            :key="classroom.id"
            no-body
            :class="{ 'border-warning': isSelectedClassroom(classroom) }"
            class="overflow-hidden card-hover mt-3"
            @click="onClickClassroom(classroom)"
          >
            <b-card-body :title="classroom.name">
              <b-card-text>
                🖥️ {{ classroom.courseCount }} courses
              </b-card-text>
            </b-card-body>
          </b-card>

          <div v-if="isLoading">
            <b-card
              no-body
              class="overflow-hidden card-hover mt-3"
              v-for="i in 2"
              :key="i"
            >
              <b-card-body>
                <b-card-text>
                  <b-skeleton
                    height="1.5em"
                    class="mb-3"
                    width="70%"
                  ></b-skeleton>
                  <b-skeleton width="40%"></b-skeleton>
                </b-card-text>
              </b-card-body>
            </b-card>
          </div>
        </b-col>

        <b-col md="8">
          <b-tabs
            v-model="tabIndex"
            active-nav-item-class="btn-dark"
            content-class="mt-3"
            pills
          >
            <b-tab :title-link-class="linkClass(0)">
              <template #title>
                <h2 class="my-0">Courses</h2>
              </template>

              <p class="mt-3" v-if="courses.length == 0 && !isLoadingCourses">
                Select a class to view your courses 📖
              </p>
              <div v-if="courses.length > 0 && !isLoadingCourses">
                <course-card
                  class="mt-3"
                  v-for="course in courses"
                  :key="course.id"
                  :course="course"
                  @card-click="onClickCourseCard(course)"
                ></course-card>
              </div>
              <div v-if="isLoadingCourses">
                <course-card-skeleton class="mb-3" />
                <course-card-skeleton />
              </div>
            </b-tab>

            <b-tab :title-link-class="linkClass(1)">
              <template #title>
                <h2 class="my-0">Tools</h2>
              </template>
              <p class="mt-3" v-if="courses.length == 0 && !isLoadingCourses">
                Select a class to view all learning tools 🧰
                <br />
                Check out some of the
                <router-link to="/tools">free tools here</router-link>.
              </p>
              <b-row class="mt-3" v-if="selectedOrganisation.id != null">
                <b-col
                  md="6"
                  class="mb-3"
                  v-for="tool in tools"
                  :key="tool.name"
                >
                  <tool-card
                    :tool="tool"
                    @card-click="onClickToolCard(tool)"
                  ></tool-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-join-class"
      ref="modal"
      title="Join Class"
      ok-variant="dark"
      @show="resetJoinClassModal"
      @hidden="resetJoinClassModal"
      @ok="handleOkJoinClass"
      :ok-disabled="isJoiningClass"
      :no-close-on-backdrop="isJoiningClass"
      :hide-header-close="isJoiningClass"
      :no-close-on-esc="isJoiningClass"
      :cancel-disabled="isJoiningClass"
    >
      <form ref="addClassForm" @submit.stop.prevent="handleSubmitJoinClass">
        <b-form-group
          label="Class id"
          invalid-feedback="Class id is required"
          :state="joinClassIdState"
        >
          <b-form-input
            type="text"
            v-model="joinClassId"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-ok>
        <b-spinner small v-if="isJoiningClass"></b-spinner>
        {{ isJoiningClass ? "Loading..." : "Join Class" }}
      </template>
    </b-modal>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import ToolCard from "@/components/ToolCard.vue";
import CourseCardSkeleton from "@/components/CourseCardSkeleton.vue";
import {
  classroomServices,
  courseServices,
  userServices,
  organisationServices
} from "@/services";
import { tools } from "@/constants/tools";
import moment from "moment";

export default {
  name: "Home",

  components: {
    CourseCard,
    ToolCard,
    CourseCardSkeleton
  },

  data() {
    return {
      classrooms: [],
      courses: [],
      tools: tools,
      selectedClassroom: {},
      selectedOrganisation: {},
      joinClassId: null,
      joinClassIdState: null,
      isLoading: true,
      isJoiningClass: false,
      isLoadingCourses: false,
      tabIndex: 0
    };
  },

  async created() {
    await this.getStudentClassrooms();
    this.isLoading = false;
  },

  computed: {
    isLicensed() {
      return this.selectedOrganisation.plan != "free";
    },

    isSubscriptionActive() {
      return this.selectedOrganisation.subscriptionEnd
        ? moment().isBefore(this.selectedOrganisation.subscriptionEnd)
        : false;
    }
  },

  methods: {
    getStudentClassrooms() {
      return classroomServices
        .getStudentClassrooms(this.$store.state.user.id)
        .then(classrooms => {
          this.classrooms = classrooms;
        })
        .catch(error => {
          alert(error.message);
        });
    },

    onClickCourseCard(course) {
      this.$router.push(
        `/classroom/${this.selectedClassroom.id}/course/${course.id}`
      );
    },

    async onClickClassroom(classroom) {
      this.selectedClassroom = classroom;
      this.isLoadingCourses = true;

      if (this.selectedClassroom.courseIds == []) {
        return;
      }

      await organisationServices
        .getOrganisation(this.selectedClassroom.organisationId)
        .then(organisation => {
          this.selectedOrganisation = organisation;
        })
        .catch(error => {
          alert(error.message);
          this.isLoadingCourses = false;
        });

      console.log(this.selectedOrganisation);

      if (!this.isLicensed || !this.isSubscriptionActive) {
        this.isLoadingCourses = false;
        return this.$bvToast.toast(
          `Classroom account is not active. Please check with your teacher.`,
          {
            title: "Classroom access",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          }
        );
      }

      await courseServices
        .getCoursesByClassroom(this.selectedClassroom.id)
        .then(courses => {
          this.courses = courses;
          this.isLoadingCourses = false;
        })
        .catch(error => {
          alert(error.message);
          this.isLoadingCourses = false;
        });
    },

    onClickToolCard(tool) {
      if (tool.isPremium && (!this.isLicensed || !this.isSubscriptionActive)) {
        this.$bvToast.toast("Upgrade your plan to access premium tools", {
          title: "Premium tools",
          autoHideDelay: 5000,
          variant: "warning",
          appendToast: true
        });
        return;
      }

      this.$router.push(tool.route);
    },

    async handleSubmitJoinClass() {
      const valid = this.$refs.addClassForm.checkValidity();
      this.joinClassIdState = valid;
      this.isJoiningClass = true;

      const classroom = await classroomServices.getClassroomByClasscode(
        this.joinClassId
      );

      if (classroom == null) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`Class of this id does not exist`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      if (classroom.studentIds.includes(this.$store.state.user.id)) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`You have already joined this class`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      const organisation = await organisationServices.getOrganisation(
        classroom.organisationId
      );

      if (organisation == null) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(
          `Organisation for this classroom not found`,
          {
            title: "Join Class",
            autoHideDelay: 5000,
            variant: "danger",
            appendToast: true
          }
        );
      }

      if (organisation.remainingLicenses <= 0) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`Organisation is out of student licenses`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      if (!classroom.isOpenToStudent) {
        this.isJoiningClass = false;
        return this.$bvToast.toast(`This class is not open for enrollment`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "danger",
          appendToast: true
        });
      }

      try {
        await classroomServices.addStudent(
          classroom.id,
          this.$store.state.user.id
        );

        await userServices.addClassroom(
          classroom.id,
          this.$store.state.user.id
        );
        await organisationServices.reduceRemainingLicense(
          classroom.organisationId
        );

        // Refresh classrooms
        this.isLoading = true;
        await this.getStudentClassrooms();
        this.isLoading = false;
        this.isJoiningClass = false;

        this.$nextTick(() => {
          this.$bvModal.hide("modal-join-class");
        });

        return this.$bvToast.toast(`Joined ${classroom.name}`, {
          title: "Join Class",
          autoHideDelay: 5000,
          variant: "success",
          appendToast: true
        });
      } catch (error) {
        alert(error.message);
        this.isJoiningClass = false;
      }
    },

    handleOkJoinClass(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmitJoinClass();
    },

    resetJoinClassModal() {
      this.joinClassId = null;
    },

    isSelectedClassroom(classroom) {
      return this.selectedClassroom.id == classroom.id;
    },

    linkClass(index) {
      if (this.tabIndex === index) {
        return [""];
      } else {
        return ["text-muted"];
      }
    }
  }
};
</script>
