<template>
  <div>
    <h2>Get List Element</h2>
    <p>
      Each element in a list can be access based on its
      <strong>index</strong> (position of the element in a list).
    </p>
    <img class="img-fluid" src="@/assets/foundation/list_intro.png" />

    <p>Use getter block to get element in list based on its index</p>
    <img class="img-fluid" src="@/assets/foundation/list_get.png" />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> 20
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Print the string <span class="block block-string">mouse</span> from
            the <span class="block block-variable">animals</span> list
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "mouse"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
