import Organisation from "@/models/organisation.model";
import { OrganisationState } from "../store.interface";
import moment from "moment";

const organisation = {
  namespaced: true,

  state: {
    id: null,
    plan: null,
    availableLicenses: 0,
    remainingLicenses: 0,
    roles: {},
    subscriptionId: null,
    subscriptionStart: null,
    subscriptionEnd: null
  },

  getters: {
    isLicensed(state: OrganisationState) {
      return state.plan != "free";
    },

    isSubscriptionActive(state: OrganisationState) {
      return state.subscriptionEnd
        ? moment().isBefore(state.subscriptionEnd)
        : false;
    }
  },

  mutations: {
    update(state: OrganisationState, organisation: Organisation) {
      state.id = organisation.id;
      state.roles = organisation.roles;
      state.plan = organisation.plan;
      state.availableLicenses = organisation.availableLicenses;
      state.remainingLicenses = organisation.remainingLicenses;
      state.subscriptionId = organisation.subscriptionId;
      state.subscriptionStart = organisation.subscriptionStart;
      state.subscriptionEnd = organisation.subscriptionEnd;
    },

    reset(state: OrganisationState) {
      state.id = null;
      state.roles = {};
      state.plan = null;
      state.availableLicenses = 0;
      state.remainingLicenses = 0;
      state.subscriptionStart = null;
      state.subscriptionEnd = null;
    }
  },

  actions: {}
};

export default organisation;
