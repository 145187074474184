<template>
  <b-card
    no-body
    class="overflow-hidden course-card card-hover"
    @click="onClickCard"
  >
    <b-row no-gutters>
      <b-col md="4">
        <b-badge class="badge-position" v-if="tool.isPremium" variant="warning">
          Premium
        </b-badge>
        <b-card-img :src="tool.imageURL" class="rounded-0" fluid-grow />
      </b-col>
      <b-col md="8">
        <b-card-body>
          <template>
            <h3>{{ tool.name }}</h3>
            <p class="my-0">{{ tool.description }}</p>
          </template>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "ToolCard",

  props: {
    tool: {
      type: Object,
      default: function() {
        return {
          name: "",
          description: "",
          imageURL: "",
          route: "",
          isPremium: false
        };
      }
    }
  },

  methods: {
    onClickCard() {
      this.$emit("card-click", this.tool);
    }
  }
};
</script>

<style lang="less" scoped>
.course-card {
  user-select: none;
}

.badge-position {
  position: absolute;
  top: 10px;
  z-index: 10;
  border-radius: 0;
}
</style>
