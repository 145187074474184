<template>
  <div>
    <h2>Exercise 22</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">total</span> and set it to 0
          </li>
          <li>
            Use a for loop, update
            <span class="block block-variable">total</span> as the sum of all
            numbers from 0 to 50.
          </li>
          <li>Print <span class="block block-variable">total</span></li>
        </ol>
        <br />
        <div>
          * use <span class="block block-variable">i</span> as your loop
          variable
        </div>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 1275
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
