<template>
  <div>
    <h2>Set List Element</h2>
    <p>
      Use setter block to set new element or replace element in list based on
      its index. Let's use this
      <span class="block block-variable">numbers</span> list as our example.
    </p>
    <img class="img-fluid" src="@/assets/foundation/list_set_numbers.png" />

    <h4 class="mt-3">Adding an element</h4>
    <p>
      The <span class="block block-variable">numbers</span> has three elements,
      hence to add a new element we set value at the 4th position.
    </p>
    <img class="img-fluid" src="@/assets/foundation/list_set_numbers_add.png" />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> [17, 36, 22, 55]
        </code>
      </b-card-text>
    </b-card>

    <h4 class="mt-3">Updating an element</h4>
    <p>
      We can replace the value of list in any position.
    </p>
    <img
      class="img-fluid"
      src="@/assets/foundation/list_set_numbers_update.png"
    />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> [99, 36, 22]
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Replace <span class="block block-string">cat</span> with
            <span class="block block-string">duck</span>
          </li>
          <li>
            Add <span class="block block-string">lion</span> into the end of the
            list
          </li>
          <li>Print <span class="block block-variable">animals</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> ["dog", "duck", "rabbit", "mouse", "lion"]
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
