<template>
  <div>
    <h2>Not Operator</h2>
    <p>
      We can use the <strong>not</strong> operator to flip the boolean evaluated
      by a conditional expression.
    </p>

    <img class="img-fluid" src="@/assets/foundation/conditional_not.png" />

    <p>For example:</p>

    <img
      class="img-fluid"
      src="@/assets/foundation/conditional_not_example.png"
    />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> false
          <br />
          >> true
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Fill in the blanks with any suitable value so that all the
            conditional expressions will be evaluated as
            <span class="block block-logic">true</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> true
          <br />
          >> true
          <br />
          >> true
          <br />
          >> true
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
