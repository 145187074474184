import { CourseBuilder } from "./builders/course.builder";

export default class Course {
  name: string;
  description: string;
  imageURL: string;
  id: string;
  organisationId: string;
  userId: string;
  language: string;
  type: string;
  status: string;
  chapterOrder: object;
  activityOrder: object;
  createdAt:
    | firebase.default.firestore.Timestamp
    | firebase.default.firestore.FieldValue;

  constructor(courseBuilder: CourseBuilder) {
    this.name = courseBuilder.getName();
    this.description = courseBuilder.getDescription();
    this.imageURL = courseBuilder.getImageURL();
    this.id = courseBuilder.getId();
    this.userId = courseBuilder.getUserId();
    this.organisationId = courseBuilder.getOrganisationId();
    this.status = courseBuilder.getStatus();
    this.type = courseBuilder.getType();
    this.chapterOrder = courseBuilder.getChapterOrder();
    this.activityOrder = courseBuilder.getActivityOrder();
    this.language = courseBuilder.getLanguage();
    this.createdAt = courseBuilder.getCreatedAt();
  }

  static fromDocumentData(
    id: string,
    data: firebase.default.firestore.DocumentData
  ) {
    return new CourseBuilder(id)
      .setName(data.name)
      .setDescription(data.description)
      .setLanguage(data.language)
      .setChapterOrder(data.chapterOrder)
      .setActivityOrder(data.activityOrder)
      .setType(data.type)
      .setStatus(data.status)
      .setImageURL(data.imageURL)
      .setOrganisationId(data.organisationId)
      .setUserId(data.userId)
      .setCreatedAt(data.createdAt)
      .build();
  }

  static converter = {
    toFirestore: function(course: Course) {
      return {
        name: course.name,
        description: course.description,
        imageURL: course.imageURL,
        organisationId: course.organisationId ?? "",
        userId: course.userId ?? "",
        type: course.type ?? "",
        chapterOrder: course.chapterOrder ?? {},
        activityOrder: course.activityOrder ?? {},
        status: course.status,
        language: course.language ?? "",
        createdAt: course.createdAt ?? ""
      };
    },
    fromFirestore: function(
      snapshot: firebase.default.firestore.DocumentSnapshot,
      options: firebase.default.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options) ?? {};
      const id = snapshot.id;
      return Course.fromDocumentData(id, data);
    }
  };
}
