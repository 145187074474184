<template>
  <div>
    <h2>Iterate List Using For Loop</h2>
    <p>
      A for loop can be used to iterate through all the items in a list.
    </p>

    <img class="img-fluid" src="@/assets/foundation/list_for_loop.png" />

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "apple"
          <br />
          >> "orange"
          <br />
          >> "melon"
          <br />
        </code>
      </b-card-text>
    </b-card>

    <p class="mt-3">
      We may not know the number of elements in a list at any certain point,
      hence we can use the <span class="block block-list">length</span> block to
      get the number of elements instead
    </p>

    <img
      class="img-fluid"
      src="@/assets/foundation/list_for_loop_with_length.png"
    />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Print out each element in
            <span class="block block-variable">colours</span> list
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "red"
          <br />
          >> "green"
          <br />
          >> "blue"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ListIntroduction"
};
</script>

<style lang="less" scoped></style>
