<template>
  <b-card
    no-body
    style="max-height: 250px;"
    class="overflow-hidden card-hover h-100 course-card"
    @click="onClickCard"
  >
    <b-row class="h-100" no-gutters>
      <b-col lg="6">
        <b-dropdown
          v-if="showSetting"
          size="lg"
          class="position-absolute gear-dropdown"
        >
          <template #button-content>
            <b-icon icon="gear-fill" />
          </template>
          <b-dropdown-item @click.stop="onClickRemove">
            Remove
          </b-dropdown-item>
        </b-dropdown>
        <b-card-img
          :src="imageURL"
          class="rounded-0 h-100"
          style="object-fit: cover;"
        ></b-card-img>
      </b-col>
      <b-col lg="6">
        <b-card-body :title="course.name">
          <b-card-text class="text-ellipsis">
            {{ course.description }}
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "CourseCard",

  props: {
    course: {
      type: Object,
      default: function() {
        return {
          name: "",
          description: "",
          imageURL: ""
        };
      }
    },
    showSetting: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    imageURL() {
      return this.course.imageURL != ""
        ? this.course.imageURL
        : "/images/course-imageplaceholder.jpg";
    }
  },

  methods: {
    onClickCard() {
      this.$emit("card-click", this.course);
    },

    onClickRemove() {
      this.$emit("remove-click", this.course);
    }
  }
};
</script>

<style lang="less" scoped>
.course-card {
  user-select: none;
}
</style>
