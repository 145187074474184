import { firestore } from "@/config/firebase";

interface Chapter {
  id: string;
  courseId: string;
  name: string;
  order: number;
}

export function getChapters(courseId: string) {
  return firestore
    .collection("chapters")
    .where("courseId", "==", courseId)
    .get()
    .then(querySnapshot => {
      const chapters: Chapter[] = [];
      querySnapshot.forEach(snapshot => {
        if (!snapshot.exists) {
          return {};
        }
        const chapter: Chapter = {
          id: snapshot.id,
          courseId: snapshot.data().courseId,
          name: snapshot.data().name,
          order: snapshot.data().order
        };
        chapters.push(chapter);
      });
      return chapters;
    });
}

export function addChapter(chapter: object) {
  return firestore.collection("chapters").add(chapter);
}

export function updateChapter(chapterId: string, update: object) {
  return firestore
    .collection("chapters")
    .doc(chapterId)
    .update(update);
}

export function deleteChapter(chapterId: string) {
  return firestore
    .collection("chapters")
    .doc(chapterId)
    .delete();
}
