/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let stepCounter = 0;
let sum = 0;

// Only write code below this line.
// Do not change the stepCounter variable (It is used for answer checking)




// Only write code above this line
console.log(sum);`;

const seedTest = `
  chaiAssert(sum === 0, "Do not change default code");
`;

export const testCases = `
  chaiAssert(sum === 1035, "Do not change default code");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  assert(
    /while\s*\([\s\S]+\)\s*{[\s\S]+}/.test(code),
    "use a while loop to complete this activity"
  );

  const matchedArrays = /while\s*\([\s\S]+\)\s*{/.exec(code);
  let firstMatched = matchedArrays ? matchedArrays[0] : "";
  firstMatched +=
    "stepCounter++; if (stepCounter > 10000) throw 'Timeout Error: while loop executed for too long, check for possible infinite loop';";
  code = code.replace(/while\s*\([\s\S]+\)\s*{/, firstMatched);
  eval(code + testCases);

  window.console = oldConsole;
  return true;
}
