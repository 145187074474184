/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let a = 5;
let b = 0;
let c = 0;
let d = 0;

// Reassign variable b, c and d below this line




// Do not modify code below this line
let answerOne = a + b;
let answerTwo = a * c;
let answerThree = d / a;
`;

const seedTest = `
  chaiAssert(a === 5, "a should have a value of 5 when defined");
  chaiAssert(b === 0, "b should have a value of 0 when defined");
  chaiAssert(c === 0, "c should have a value of 0 when defined");
  chaiAssert(d === 0, "d should have a value of 0 when defined");
`;

export const testCases = `
  chaiAssert.strictEqual(answerOne, 15, "answerOne");
  chaiAssert.strictEqual(answerTwo, 20, "answerTwo");
  chaiAssert.strictEqual(answerThree, 6, "answerThree");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 4; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  assert(
    /let\sanswerOne\s=\sa\s\+\sb;/.test(code),
    "Do not modify answerOne expression"
  );
  assert(
    /let\sanswerTwo\s=\sa\s\*\s+c;/.test(code),
    "Do not modify answerTwo expression"
  );
  assert(
    /let\sanswerThree\s=\sd\s\/\sa;/.test(code),
    "Do not modify answerThree expression"
  );
  eval(code + testCases);

  window.console = oldConsole;
  return true;
}
