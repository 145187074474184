/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `const x = 52.33455;

// Only write code below this line
`;

const seedTest = `
  chaiAssert(x === 52.33455, "x should have a value of 52.33455 when defined");
`;

export const testCases = `
  chaiAssert.strictEqual(roundedNumber, 52, "roundedNumber");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);
  assert(
    /randomNumber\s*=\s*Math.random\(\)\s*;/.test(code),
    "randomNumber not defined using Math object"
  );

  assert(
    /roundedNumber\s*=\s*Math.round\(\s*x\s*\)\s*;/.test(code),
    "roundedNumber not defined using Math object"
  );

  window.console = oldConsole;
  return true;
}
