import { firestoreFieldValue } from "@/config/firebase";
import Course from "../course.model";

export class CourseBuilder {
  private _id = "";
  private _name = "";
  private _description = "";
  private _imageURL = "";
  private _userId = "";
  private _organisationId = "";
  private _type = "";
  private _language = "";
  private _status = "";
  private _chapterOrder = {};
  private _activityOrder = {};
  private _createdAt:
    | firebase.default.firestore.Timestamp
    | firebase.default.firestore.FieldValue = firestoreFieldValue.serverTimestamp();

  constructor(id: string) {
    this._id = id;
  }

  build() {
    return new Course(this);
  }

  getId() {
    return this._id;
  }

  setName(name: string) {
    this._name = name;
    return this;
  }

  getName() {
    return this._name;
  }

  setDescription(description: string) {
    this._description = description;
    return this;
  }

  getDescription() {
    return this._description;
  }

  setImageURL(imageURL: string) {
    this._imageURL = imageURL;
    return this;
  }

  getImageURL() {
    return this._imageURL;
  }

  setOrganisationId(organisationId: string) {
    this._organisationId = organisationId;
    return this;
  }

  getOrganisationId() {
    return this._organisationId;
  }

  setUserId(userId: string) {
    this._userId = userId;
    return this;
  }

  getUserId() {
    return this._userId;
  }

  setType(type: string) {
    this._type = type;
    return this;
  }

  getType() {
    return this._type;
  }

  setLanguage(language: string) {
    this._language = language;
    return this;
  }

  getLanguage() {
    return this._language;
  }

  setChapterOrder(order: object) {
    this._chapterOrder = order;
    return this;
  }

  getChapterOrder() {
    return this._chapterOrder;
  }

  setActivityOrder(order: object) {
    this._activityOrder = order;
    return this;
  }

  getActivityOrder() {
    return this._activityOrder;
  }

  setStatus(status: string) {
    this._status = status;
    return this;
  }

  getStatus() {
    return this._status;
  }

  setCreatedAt(createdAt: firebase.default.firestore.Timestamp) {
    this._createdAt = createdAt;
    return this;
  }

  setCreatedAtAsCurrent() {
    this._createdAt = firestoreFieldValue.serverTimestamp();
    return this;
  }

  getCreatedAt() {
    return this._createdAt;
  }
}
