<template>
  <div>
    <h2>Exercise 26</h2>
    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">total</span>
          </li>
          <li>
            Use nested for loops, calculate the sum of following calculation and
            assign to <span class="block block-variable">total</span>
            <ul>
              <li>
                11 x 3
              </li>
              <li>
                11 x 4
              </li>
              <li>
                11 x 5
              </li>
              <li>
                11 x 6
              </li>
              <li>
                12 x 3
              </li>
              <li>
                12 x 4
              </li>
              <li>
                12 x 5
              </li>
              <li>
                12 x 6
              </li>
            </ul>
          </li>
          <li>
            For inner loop use <span class="block block-variable">j</span> as
            its counting variable
          </li>
          <li>Print <span class="block block-variable">total</span></li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 414
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
