<template>
  <div>
    <h2>Exercise 2</h2>
    <p>
      Computers treat different data types differently. For example, we can only
      perform mathematic operations on a variable with the data type of Number.
    </p>
    <p>
      In most programming languages, a String is usually enclosed with a pair of
      quotes to distinguish between a Number.
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">myNumberString</span> and assign
            it with a string of <span class="block block-string">100</span>.
          </li>
          <li>
            Create a variable named
            <span class="block block-variable">myNumber</span> and assign it
            with a number of <span class="block block-math">100</span>.
          </li>
          <li>
            Print <span class="block block-variable">myNumberString</span> and
            <span class="block block-variable">myNumber</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "100"
          <br />
          >> 100
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
