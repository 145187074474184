<template>
  <div>
    <h2>Exercise 28</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable <span class="block block-variable">total</span>
          </li>
          <li>
            Using a while loop to loop through all whole number from 1, 2, 3, 4,
            5.. and so on. Add each number to
            <span class="block block-variable">total</span>
          </li>
          <li>
            Stop the while loop and print value of
            <span class="block block-variable">total</span> when it is first
            more than 1000
          </li>
        </ol>

        <p class="mb-0 mt-2">
          *Use only ≤ operator to solve this activity
        </p>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 1035
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
