/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri"];

// Only write code below this line
`;

const seedTest = `
  chaiAssert.deepEqual(weekdays,["Mon", "Tue", "Wed", "Thu", "Fri"], "Do not change default code");
`;

export const testCases = `
  chaiAssert.deepEqual(weekdays, ["Fri", "Mon"], "weekdays");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);

  assert(
    /weekdays.reverse\(\)\s*;/.test(code),
    "use array method to reverse the array"
  );

  assert(
    /weekdays.splice\(\s*1\s*,\s*3\s*\)\s*;/.test(code),
    "use array method to remove elements in array"
  );

  window.console = oldConsole;
  return true;
}
