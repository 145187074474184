export default class Activity {
  id: string;
  courseId = "";
  chapterId = "";
  name = "";
  order = 0;
  type = "";
  language = "";
  tests? = {};
  blocklyToolbox? = "";
  workspaceText? = "";
  content? = "";
  seed? = "";
  layout? = "";

  constructor(id: string, data: firebase.default.firestore.DocumentData) {
    this.id = id;
    this.blocklyToolbox = data.blocklyToolbox;
    this.courseId = data.courseId;
    this.name = data.name;
    this.order = data.order;
    this.language = data.language;
    this.chapterId = data.chapterId;
    this.type = data.type;
    this.workspaceText = data.workspaceText;
    this.content = data.content;
    this.seed = data.seed;
    this.tests = data.tests || { seed: [], user: [] };
    this.layout = data.layout;
  }

  static converter = {
    toFirestore: function(activity: Activity) {
      return {
        name: activity.name,
        blocklyToolbox: activity.blocklyToolbox,
        courseId: activity.courseId
      };
    },
    fromFirestore: function(
      snapshot: firebase.default.firestore.DocumentSnapshot,
      options: firebase.default.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options) || {};
      const id = snapshot.id;
      return new Activity(id, data);
    }
  };
}
