import { firestore, firestoreFieldValue } from "@/config/firebase";
import Organisation from "@/models/organisation.model";

interface OrganisationInterface {
  name?: string;
  contact?: string;
  email?: string;
  addressOne?: string;
  addressTwo?: string;
  studentCount?: number;
  roles?: Record<string, string>;
}

export function addOrganisation(organisation: OrganisationInterface) {
  return firestore.collection("organisations").add(organisation);
}

export function getOrganisation(organisationId: string) {
  return firestore
    .collection("organisations")
    .doc(organisationId)
    .withConverter(Organisation.converter)
    .get()
    .then(snapshot => {
      return snapshot.data();
    });
}

export function reduceRemainingLicense(organisationId: string) {
  return firestore
    .collection("organisations")
    .doc(organisationId)
    .update({
      remainingLicenses: firestoreFieldValue.increment(-1)
    });
}

export function increaseRemainingLicense(organisationId: string) {
  return firestore
    .collection("organisations")
    .doc(organisationId)
    .update({
      remainingLicenses: firestoreFieldValue.increment(1)
    });
}

export function updateOrganisation(
  id: string,
  organisation: OrganisationInterface
) {
  return firestore
    .collection("organisations")
    .doc(id)
    .update(organisation);
}

export async function addStudent(
  organisationId: string,
  studentId: string,
  classroomId: string
) {
  const studentSnapshot = await firestore
    .collection("organisations")
    .doc(organisationId)
    .collection("organisationStudents")
    .doc(studentId)
    .get();

  if (studentSnapshot.exists) {
    return studentSnapshot.ref.update({
      classroomIds: firestoreFieldValue.arrayUnion(classroomId)
    });
  } else {
    return studentSnapshot.ref.set({
      createdAt: firestoreFieldValue.serverTimestamp(),
      classroomIds: [classroomId]
    });
  }
}
