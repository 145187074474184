import { firestore, firestoreFieldValue } from "@/config/firebase";
import UserInterface from "@/models/interfaces/user.interface";
import User from "@/models/user.model";

export function addUser(user: UserInterface) {
  user.createdAt = firestoreFieldValue.serverTimestamp();
  return firestore
    .collection("users")
    .doc(user.id)
    .set(user);
}

export function getUser(id: string) {
  return firestore
    .collection("users")
    .doc(id)
    .withConverter(User.converter)
    .get()
    .then(snapshot => {
      const user = snapshot.data();
      if (user == null) {
        throw new Error("User does not exist");
      }
      return user;
    });
}

export function updateUser(userId: string, user: UserInterface) {
  return firestore
    .collection("users")
    .doc(userId)
    .update(user);
}

export function addClassroom(classroomId: string, studentId: string) {
  return firestore
    .collection("users")
    .doc(studentId)
    .update({ classroomIds: firestoreFieldValue.arrayUnion(classroomId) });
}

export function deleteClassroom(classroomId: string, studentId: string) {
  return firestore
    .collection("users")
    .doc(studentId)
    .update({ classroomIds: firestoreFieldValue.arrayRemove(classroomId) });
}
