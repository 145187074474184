<template>
  <div>
    <h2>Exercise 4</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Rename the variables
            <span class="block block-variable">studentname</span>,
            <span class="block block-variable">hassubmithomework</span>, and
            <span class="block block-variable">mypocketmoney</span> to use
            <strong>Camel Case</strong> convention
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
