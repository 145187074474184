<template>
  <div>
    <h2>Variable Naming</h2>
    <p>
      Most programming languages use the
      <strong>Camel Case</strong> convention to name variables. The first word
      starts with all lower case while the next word begins with a capital
      letter. For example:
    </p>
    <ul>
      <li>myName</li>
      <li>myFavouriteColour</li>
      <li>studentList</li>
      <li>isGoodStudent</li>
    </ul>

    <p>
      Although you can name your variable anything, the variable name created
      should be meaningful and allows a programmer to easily identify what is
      stored in that variable. For example, if you want to store the number of
      students in a classroom an example would be
      <span class="block block-variable">classroomStudentCount</span>
    </p>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Rename the
            <span class="block block-variable">numberoflegs</span> variable to
            follow Camel Case convention
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
