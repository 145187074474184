import { render, staticRenderFns } from "./Exercise18.vue?vue&type=template&id=e8f3e23c&scoped=true&"
import script from "./Exercise18.vue?vue&type=script&lang=js&"
export * from "./Exercise18.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8f3e23c",
  null
  
)

export default component.exports