<template>
  <div>
    <h2>Introduction to Operators</h2>
    <p>
      Operators are different symbols that represent an operation. Operations
      enable us to process our data and transform it into something else. We can
      use a computer program to do simple calculations as well as complex
      equations.
    </p>
    <p>
      A typical computer program needs a lot of variables to store different
      information. Hence, we need to name our variables so that we can retrieve
      the information that we stored.
    </p>
    <h3>Blockly</h3>
    <p>
      You can use the operator block do typical math operations.
    </p>
    <img class="img-fluid" src="@/assets/foundation/operator.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable named
            <span class="block block-variable">numberOne</span> and assign it a
            value of 15
          </li>
          <li>
            Create a variable named
            <span class="block block-variable">numberTwo</span> and assign it a
            value of 18
          </li>
          <li>
            Create a variable named
            <span class="block block-variable">total</span> and assign it to the
            value of
            <span class="block block-variable">numberOne</span> multiple
            <span class="block block-variable">numberTwo</span>
          </li>
          <li>
            Print
            <span class="block block-variable">total</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> 270
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
