<template>
  <div>
    <h2>Exercise 13</h2>
    <p>
      Since a conditional expression evaluates to a boolean value, it can be
      stored in a variable.
    </p>
    <img
      class="img-fluid"
      src="@/assets/foundation/conditional_if_variable.png"
    />
    <p>
      In the example above, since
      <span class="block block-variable">number</span> is more than twenty, the
      variable <span class="block block-variable">isMoreThanTwenty</span> will
      have the value of <span class="block block-logic">true</span>
    </p>
    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          >> "is more than 20!"
        </code>
      </b-card-text>
    </b-card>

    <h3 class="mt-3">Activity</h3>

    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">myFavouriteFruit</span>, assign
            it as string of
            <span class="block block-string">apple</span>
          </li>
          <li>
            Create a variable <span class="block block-variable">isApple</span>,
            assign it a boolean by comparing whether the value of
            <span class="block block-variable">myFavouriteFruit</span> is
            <span class="block block-string">apple</span>
          </li>
          <li>
            Use a if statement, print a text saying
            <span class="block block-string">I love apple too!</span> if
            <span class="block block-variable">isApple</span> is
            <span class="block block-logic">true</span>
          </li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "I love apple too!"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
