<template>
  <b-card
    style="max-height: 250px;"
    class="overflow-hidden card-hover h-100 course-card"
    no-body
  >
    <b-row no-gutters>
      <b-col lg="6">
        <b-skeleton-img card-img="left" height="250px"></b-skeleton-img>
      </b-col>
      <b-col lg="6">
        <b-card-body>
          <b-card-text>
            <b-skeleton height="1.5rem"></b-skeleton>
            <b-skeleton height="1.5rem" width="55%" class="mb-3"></b-skeleton>
            <b-skeleton width="95%"></b-skeleton>
            <b-skeleton></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "CourseCardSkeleton"
};
</script>

<style></style>
