import { firestoreFieldValue } from "@/config/firebase";
import Classroom from "../classroom.model";

export class ClassroomBuilder {
  private _id = "";
  private _name = "";
  private _classCode = "";
  private _organisationId = "";
  private _studentIds: string[] = [];
  private _studentCount = 0;
  private _courseCount = 0;
  private _isOpenToStudent = true;
  private _createdBy = "";
  private _createdAt:
    | firebase.default.firestore.Timestamp
    | firebase.default.firestore.FieldValue = firestoreFieldValue.serverTimestamp();

  constructor(id: string) {
    this._id = id;
  }

  setName(name: string) {
    this._name = name;
    return this;
  }

  setClassCode(classCode: string) {
    this._classCode = classCode;
    return this;
  }

  setOrganisationId(id: string) {
    this._organisationId = id;
    return this;
  }

  setStudentIds(studentIds: string[]) {
    this._studentIds = studentIds ?? [];
    return this;
  }

  setStudentCount(count: number) {
    this._studentCount = count ?? 0;
    return this;
  }

  setCourseCount(count: number) {
    this._courseCount = count ?? 0;
    return this;
  }

  setIsOpenToStudent(isOpen: boolean) {
    this._isOpenToStudent = isOpen ?? true;
    return this;
  }

  setCreatedBy(id: string) {
    this._createdBy = id;
    return this;
  }

  setCreatedAt(createdAt: firebase.default.firestore.Timestamp) {
    this._createdAt = createdAt;
    return this;
  }

  setCreatedAtAsCurrent() {
    this._createdAt = firestoreFieldValue.serverTimestamp();
    return this;
  }

  build() {
    return new Classroom(this);
  }

  getId() {
    return this._id;
  }

  getClassCode() {
    return this._classCode;
  }

  getName() {
    return this._name;
  }

  getOrganisationId() {
    return this._organisationId;
  }

  getStudentIds() {
    return this._studentIds;
  }

  getStudentCount() {
    return this._studentCount;
  }

  getCourseCount() {
    return this._courseCount;
  }

  isOpenToStudent() {
    return this._isOpenToStudent;
  }

  getCreatedBy() {
    return this._createdBy;
  }

  getCreatedAt() {
    return this._createdAt;
  }
}
