<template>
  <div>
    <h2>Introduction to Loop</h2>
    <p>
      Loops are used to instruct the computer to do something over and over
      again. This is what makes a computer so powerful in carrying out a
      repetitive task. A computer can repeat a line of code a million times in a
      second! There are two main types of loops we can use in most programming
      languages - <strong>For Loop</strong> and <strong>While Loop</strong>
    </p>

    <h4>For Loop</h4>
    <p>
      A FOR loop will carry out instructions for a given number of times. For
      example in the code below, the code inside FOR loop will be run 5 times.
    </p>
    <img class="img-fluid" src="@/assets/foundation/loop_intro.png" />
    <p>
      In each for loop, we will need a variable as a counter to keep track of
      the number of loops. In example the above, variable
      <span class="block block-variable">i</span> was created as the counter for
      this loop. You also notice 3 values in the for loop
    </p>
    <ul>
      <li>
        from - the initial value of <span class="block block-variable">i</span>
      </li>
      <li>
        to - the condition of <span class="block block-variable">i</span> where
        the for loop will stop
      </li>
      <li>
        by - how will the variable
        <span class="block block-variable">i</span> change after each loop
      </li>
    </ul>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>Set the for loop to repeat for 3 times</li>
        </ol>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" bg-variant="dark" text-variant="white">
      <b-card-text>
        <code class="text-white">
          Expected result:
          <br />
          >> "Hello!"
          <br />
          >> "Hello!"
          <br />
          >> "Hello!"
        </code>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
