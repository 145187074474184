<template>
  <div>
    <h2>Exercise 15</h2>

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Create a variable
            <span class="block block-variable">myFavouriteFruit</span>, set it
            to any fruit's name
          </li>
          <li>
            Use a IF statement, print a text saying
            <span class="block block-string">I love apple too!</span> if
            <span class="block block-variable">myFavouriteFruit</span> is
            <span class="block block-string">apple</span>
          </li>
          <li>
            Print a text saying
            <span class="block block-string">Yuks I do not like banana!</span>
            else if
            <span class="block block-variable">myFavouriteFruit</span> is
            <span class="block block-string">banana</span>
          </li>
          <li>
            Print a text saying
            <span class="block block-string">That is not a fruit!</span> else if
            <span class="block block-variable">myFavouriteFruit</span> is
            <span class="block block-string">carrot</span>
          </li>
          <li>
            Print a text saying
            <span class="block block-string">I guess that is ok</span> for any
            other fruit
          </li>
        </ol>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
