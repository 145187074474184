/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `let someNumber = "fifty";

// Only write your code below this line
`;

const seedTest = `
  chaiAssert.isDefined(someNumber);
  chaiAssert(typeof someNumber === "string", "Do not change the default code");
  chaiAssert.strictEqual(someNumber, "fifty");
`;

export const testCases = `
  chaiAssert(typeof someNumber === "number", "someNumber should be a number");
  chaiAssert.strictEqual(someNumber, 50);
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 1; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);
  eval(code + testCases);
  window.console = oldConsole;
  return true;
}
