<template>
  <div>
    <h2>Exercise 17</h2>
    <p class="mb-0">
      If a number is divisible by 2 with no remainder, then it is even. Else,
      it's an odd number.
    </p>
    <img class="img-fluid" src="@/assets/foundation/long-division.png" />

    <h3 class="mt-3">Activity</h3>
    <b-card border-variant="primary">
      <b-card-text>
        <ol class="activity-list">
          <li>
            Use an if statement, print a text
            <span class="block block-string">
              It is an even number greater than 50!
            </span>
            if <span class="block block-variable">randomNum</span> is an even
            number <strong>AND</strong>
            it is greater than 50
          </li>
          <li>
            Else, print
            <span class="block block-string">
              It is not!
            </span>
          </li>
        </ol>

        <p class="mt-2 mb-0">*Use only the = operator to solve this activity</p>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FoundationVariable"
};
</script>

<style lang="less" scoped></style>
