/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { assert } from "chai";
import customConsole from "@/utils/custom-console";

export const seed = `const a = 15;
const b = 0;

// Only change code below this line;
let expOne = a == 0;
let expTwo = b != 0;
let expThree = -10 > 0;
let expFour = 30 < 0;
`;

const seedTest = `
  chaiAssert(typeof a === "number", "Do not change the default code");
  chaiAssert.strictEqual(a, 15);

  chaiAssert(typeof b === "number", "Do not change the default code");
  chaiAssert.strictEqual(b, 0);
`;

export const testCases = `
  chaiAssert.isTrue(expOne, "wrong answer for expOne");
  chaiAssert.isTrue(expTwo, "wrong answer for expTwo");
  chaiAssert.isTrue(expThree, "wrong answer for expThree");
  chaiAssert.isTrue(expFour, "wrong answer for expFour");
`;

export function testCorrectness(code: string, self: any) {
  const chaiAssert = assert;
  const oldConsole = window.console;

  const splitCodes = code.split("\n");
  let seedCode = "";
  for (let index = 0; index < 2; index++) {
    seedCode += splitCodes[index];
  }
  eval(seedCode + seedTest);

  window.console = customConsole(self);

  eval(code + testCases);

  assert(
    /let expOne = a ==\s*\d+\s*;/.test(code),
    "expOne: change only the number 0"
  );
  assert(
    /let expTwo = b !=\s*\d+\s*;/.test(code),
    "expTwo: change only the number 0"
  );
  assert(
    /let expThree = -10 >\s*-\d+\s*;/.test(code),
    "expThree: change only the number 0"
  );
  assert(
    /let expFour = 30 <\s*\d+\s*;/.test(code),
    "expFour: change only the number 0"
  );

  window.console = oldConsole;
  return true;
}
