<template>
  <div>
    <b-row no-gutters>
      <b-col md="6" class="bg-dark"></b-col>
      <b-col md="6">
        <div
          class="login-panel d-flex justify-content-center align-items-center flex-column"
        >
          <h3 class="mb-3">Login</h3>
          <div class="login-button-container">
            <b-form @submit.prevent="onClickLoginWithEmail">
              <b-form-group>
                <b-form-input
                  v-model="email"
                  type="email"
                  placeholder="Email"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="password"
                  type="password"
                  placeholder="Password"
                ></b-form-input>
              </b-form-group>
              <div>
                <b-button
                  block
                  type="submit"
                  variant="warning"
                  class="login-button"
                  @click="onClickLoginWithEmail"
                  :disabled="isLoggingin"
                >
                  <b-spinner small v-if="isLoggingin"></b-spinner>
                  {{ isLoggingin ? "Loading..." : "Login" }}
                </b-button>

                <b-button block variant="primary" to="/signup">
                  Create New Account
                </b-button>
              </div>
            </b-form>
          </div>

          <div>
            <b-button
              class="text-muted"
              variant="link"
              @click="onClickForgetPassword"
            >
              Forget Password
            </b-button>
          </div>
          <div class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { authServices } from "@/services";

export default {
  name: "Login",

  data() {
    return {
      classroomId: this.$route.query.cl,
      email: "",
      password: "",
      errorMessage: "",
      isLoggingin: false
    };
  },

  computed: {
    signUpPath() {
      const query = this.classroomId != null ? `?cl=${this.classroomId}` : "";
      return "/signup" + query;
    }
  },

  methods: {
    onClickLoginWithEmail() {
      this.isLoggingin = true;
      this.resetErrorMessage();
      return authServices
        .loginUser(this.email, this.password)
        .then(async userCredential => {
          await this.$store.dispatch("login", userCredential.user.uid);
          this.$analytics.logEvent("login");
          this.afterLogin();
        })
        .catch(error => {
          this.errorMessage = error.message;
          this.isLoggingin = false;
        });
    },

    onClickForgetPassword() {
      return authServices
        .forgetPassword(this.email)
        .then(() => {
          this.errorMessage =
            "Email for password reset is sent. Please check your email.";
        })
        .catch(error => {
          if (error.code == "auth/invalid-email") {
            this.errorMessage = "Please enter your email address to reset";
          } else if (error.code == "auth/user-not-found") {
            this.errorMessage = "No user was found with this email address";
          } else {
            this.errorMessage = error.message;
          }
        });
    },

    afterLogin() {
      if (this.classroomId != null) {
        this.$router.push(`/invite/${this.classroomId}`);
      } else {
        this.$router.push("/dashboard");
      }
    },

    resetErrorMessage() {
      this.errorMessage = "";
    }
  }
};
</script>

<style lang="less" scoped>
.login-panel {
  background-color: white;
  height: 100vh !important;
}

@media (max-width: 768px) {
  .login-button-container {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .login-button-container {
    width: 50%;
  }
}

.error-message {
  width: 50%;
  color: red;
  text-align: center;
}
</style>
